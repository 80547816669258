import React from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { marked } from 'marked'
import { sanitize } from 'dompurify'
import { AppContext } from '../contexts/AppContext'
import { isLoggedIn } from './Login'
import { History } from 'history'
import { Dispatch, PrimaryAction } from '../helpers/action/Action.d'
import { AppContextState } from '../contexts/AppContext.d'
library.add(faAngleRight)

import AboutMarkdown from './about/about'
import MoreMarkdown from './about/more'
import PrivacyMarkdown from './about/privacy'
import ErrorMessage from '../common/ErrorMessage'

const markdowns: Record<string, string> = {
	about: AboutMarkdown,
	more: MoreMarkdown,
	privacy: PrivacyMarkdown
}

/* eslint-disable dot-notation, @typescript-eslint/naming-convention */

const DEFAULT_PAGE = 'about'

export const About = (): JSX.Element => {
	const params = useParams<{ page?: string }>()
	const page = params.page || DEFAULT_PAGE
	return <div className='app'>
		<Breadcrumbs page={page} />
		<AboutPage key={page} page={page} />
	</div>
}

const Breadcrumbs = (props: { page: string }): JSX.Element =>
	<div id='breadcrumbs'>
		<Link to='/'><img src='/images/logo.svg' alt='Trove' /></Link>
		<FontAwesomeIcon icon='angle-right' /><Link to={`/${DEFAULT_PAGE}/`}>About</Link>
		{ props.page !== DEFAULT_PAGE && <>
			<FontAwesomeIcon icon='angle-right' />
			<Link to={`/${DEFAULT_PAGE}/${props.page}`}>{props.page.substr(0, 1).toUpperCase() + props.page.substr(1)}</Link>
		</>}
	</div>

const linkTarget = (href: string): string => href.replace(/.*\/about/, '/about')

/** Hackily take control of Markdown links, to avoid having to reload the page each time. */
const linkListener = (appState: AppContextState, appDispatch: Dispatch<PrimaryAction>, history: History)
: React.MouseEventHandler<HTMLDivElement> =>
	(e) => {
		if (e.target['tagName'] === 'A') {
			if (e.target['href'].startsWith('mailto:')) {
				return
			} else if (e.target['href'].includes('/about/account/')) {
				const user = linkTarget(String(e.target['href'])).substring(15)
				e.preventDefault()
				if (isLoggedIn(appState)) {
					e.target['classList'].toggle('disabled')
				} else {
					history.push('/write?date=1970-01-01')
					appDispatch({ type: 'SKIP_AUTH_TO_USER', user })
				}
				return
			}
			history.push(linkTarget(String(e.target['href'])))
			e.preventDefault()
		}
	}

const AboutPage = (props: { page: string }): JSX.Element => {
	const history = useHistory()
	const { appState, appDispatch } = React.useContext(AppContext)
	if (!(props.page in markdowns))
		return <ErrorMessage status={404} />
	return <div className='about-page' role='article'
		onClick={linkListener(appState, appDispatch, history)}
		dangerouslySetInnerHTML={markdownHtml(markdowns[props.page])} />
}

const markdownHtml = (markdown: string): { __html: string } =>
	({ __html: sanitize(marked(markdown)) })
