import React, { useContext } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SimpleEntryWriteComponent, SIMPLE_ENTRY_WRITE_PROPS } from './Write.d'
import { faStar, faBolt, faAward, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../contexts/AppContext'
import { isUsingField } from '../contexts/app/auth'
import { AppContextState } from '../contexts/AppContext.d'
import { VoiceRecorder } from './VoiceRecord'
library.add(faStar, faBolt, faAward, faMoneyBill)

const isUsingRatings = (appState: AppContextState): boolean =>
	isUsingField('rating', appState) || isUsingField('energy', appState) || isUsingField('importance', appState)

const RatingsAndPriceBlock: SimpleEntryWriteComponent = (props): JSX.Element => {
	const { appState } = useContext(AppContext)
	if (!isUsingRatings(appState) && !isUsingField('price', appState) && !isUsingField('voice', appState)) {
		return <></>
	}
	return <div className='entry-write-metadata'>
		{ isUsingField('voice', appState) && <VoiceRecorder /> }
		{ isUsingRatings(appState)        && <RatingsBlock entry={props.entry} setValue={props.setValue} /> }
		{ isUsingField('price', appState) && <PriceField entry={props.entry} setValue={props.setValue} /> }
	</div>
}
RatingsAndPriceBlock.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const RatingsBlock: SimpleEntryWriteComponent = (props): JSX.Element => {
	const { appState } = useContext(AppContext)
	return <div className='no-line-wrap'>
		{ isUsingField('rating', appState) && <RatingField entry={props.entry} setValue={props.setValue} />}
		{ isUsingField('energy', appState) && <EnergyField entry={props.entry} setValue={props.setValue} /> }
		{ isUsingField('importance', appState) && <ImportanceField entry={props.entry} setValue={props.setValue} />}
	</div>
}
RatingsBlock.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const RatingField: SimpleEntryWriteComponent = (props) => (
	<div className='no-line-wrap'>
		<label htmlFor='rating'><FontAwesomeIcon icon='star' /></label>
		<input id='rating' value={props.entry.rating || ''} placeholder='e.g. 4' type='number' step='0.1'
			onChange={e => props.setValue('rating', Number(e.target.value)) } />
	</div>)
RatingField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const EnergyField: SimpleEntryWriteComponent = (props) => (
	<div className='no-line-wrap'>
		<label htmlFor='energy'><FontAwesomeIcon icon='bolt' /></label>
		<input id='energy' value={props.entry.energy || ''} placeholder='e.g. 3' type='number' step='0.1'
			onChange={e => props.setValue('energy', Number(e.target.value)) } />
	</div>)
EnergyField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const ImportanceField: SimpleEntryWriteComponent = (props) => (
	<div className='no-line-wrap'>
		<label htmlFor='importance'><FontAwesomeIcon icon='award' /></label>
		<input id='importance' value={props.entry.importance || ''} placeholder='e.g. 5' type='number' step='0.1'
			onChange={e => props.setValue('importance', Number(e.target.value)) } />
	</div>)
ImportanceField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const PriceField: SimpleEntryWriteComponent = (props) => (
	<div className='no-line-wrap'>
		<label htmlFor='price'><FontAwesomeIcon icon='money-bill' /></label>
		<input id='price' value={props.entry.price || ''} placeholder='e.g. €35'
			onChange={e => props.setValue('price', e.target.value) } />
	</div>)
PriceField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

export default RatingsAndPriceBlock
