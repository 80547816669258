import React from 'react'
import { EntryThumbnailListWithDate, EntryThumbnailListWithField } from './EntryThumbnailList'
import { Route, Switch } from 'react-router-dom'
import EntryWrite from '../Write/Write'
import VoiceContextProvider from '../contexts/VoiceContext'

const EntryPage = (): JSX.Element =>
	<EntryPageContents />

const EntryPageContents = (): JSX.Element =>
	<div className="page entry-page">
		<Switch>
			<Route path='/entries/on/:date'>
				<EntryThumbnailListWithDate />
			</Route>
			<Route path={[
				'/entries/with/:field/:comparison/:value',
				'/entries/by/:field/:comparison/:value',
				'/entries/with/:field/:value',
				'/entries/by/:field/:value',
				'/entries/by/:field'
			]}>
				<EntryThumbnailListWithField />
			</Route>
			<Route>
				<VoiceContextProvider>
					<EntryWrite />
				</VoiceContextProvider>
			</Route>
		</Switch>
	</div>

export default EntryPage
