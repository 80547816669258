import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import PersonLink from '../Person/PersonLink'
import { AppContext } from '../contexts/AppContext'
import { Entry, PROP_TYPES_ENTRY } from './Entry.d'
import { ENTRY_TYPE_NORMAL } from '../Write/config'
import { formatPage, outputTime } from '../helpers/format'
import { EntryContext } from '../contexts/EntryContext'

// Note that this must contain format.js:regexTime.
const regexTimeInText = /(\n|^)\s*-?\s*(~?)([0-9]{2,})[: ]?([0-5][0-9])(~?)(:| -)? /g

/* Format times of day consistently throughout summary and entry */
const replaceTimesOfDay = (fullText: string, start: number, end: number | undefined, keyStart: number): (JSX.Element | string)[] => {
	const text = fullText.substring(start, end)

	const segments: (JSX.Element | string)[] = []
	let startPos = 0

	const matches = text.matchAll(regexTimeInText)
	for (const match of [...matches]) {
		// Matches have to be at the start of their respective line.
		if (keyStart !== 0 && fullText.substr(start + (match.index || 0), 1) !== '\n') {
			continue
		}

		const time = outputTime(match[0], true)

		segments.push(text.substring(startPos, match.index || 0))
		if (match[0][0] === '\n') {
			segments.push('\n')
		}
		segments.push(
			<span key={keyStart + segments.length + 1}>
				&bull;&nbsp;{time}:&nbsp;
			</span>)
		startPos = (match.index || 0) + match[0].length
	}
	segments.push(text.substring(startPos))

	return segments
}

/* Replace names with <PersonLink>s pointing to their /person/ page */
const replaceNamesAndTimes = (text: string, matches: string, summaryLength: number): (JSX.Element | string)[] => {
	let segments: (JSX.Element | string)[] = []
	let startPos = 0
	for (const match of matches.split('; ')) {
		if (!match) { continue }
		const entryParts = match.split('@')
		const positions = entryParts[1].split('-')
		const start = parseInt(positions[0]) - summaryLength
		const end = parseInt(positions[1]) - summaryLength
		if (start < 0 || start > text.length) { continue }
		const person = <PersonLink
			key={segments.length}
			text={text.substring(start - 1, end)}
			personId={entryParts[0]} />
		segments = segments.concat(replaceTimesOfDay(text, startPos, start - 1, segments.length))
		segments.push(person)
		startPos = end
	}
	segments = segments.concat(replaceTimesOfDay(text, startPos, undefined, segments.length))
	return segments
}

const MAX_ENTRY_PREVIEW = 40

const EntryContentText = (props: { entry: Entry, showSummary?: boolean }): JSX.Element => {
	const { hasLoginScope } = useContext(AppContext)
	const { state } = useContext(EntryContext)

	const text = (!props.showSummary
		? props.entry.entry
		: props.entry.summary
			? props.entry.summary
			: (!props.entry.entry || props.entry.entry.length <= MAX_ENTRY_PREVIEW)
				? props.entry.entry
				: props.entry.entry.substring(0, MAX_ENTRY_PREVIEW - 2) + '…')
		|| ''

	if (!text && !props.entry.summary && !props.entry.title) {
		return hasLoginScope('write')
			? <div className='tip'>This entry is empty.</div>
			: <></>
	}

	const summaryLength = !props.showSummary && props.entry.summary
		? props.entry.summary.length + 1 : 0

	const segments = ['processed', 'deleted'].includes(props.entry.status || '') && !(state.write && props.entry.id in state.write)
		? replaceNamesAndTimes(text, props.entry.matches || '', summaryLength)
		: text

	return <>
		{ props.showSummary && <h3 className='tip'>{segments}</h3>}
		{ props.entry.type !== ENTRY_TYPE_NORMAL && props.entry.endText && !hasLoginScope('write') &&
				<div className='tip'>Pages { formatPage(props.entry.date) } to { formatPage(props.entry.date, props.entry.endText) }</div> }
		{ !props.showSummary && segments }
	</>
}

const entryContentTextPropTypes = {
	showSummary: PropTypes.bool,
	entry: PROP_TYPES_ENTRY.isRequired
}
EntryContentText.propTypes = entryContentTextPropTypes

export default EntryContentText
