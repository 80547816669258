import React, { useContext } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import HttpStatus from 'http-status-codes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartBroken, faPlaneArrival, faSpinner, faMeh, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../contexts/AppContext'
library.add(faHeartBroken, faPlaneArrival, faSpinner, faMeh, faSignOutAlt)

const ErrorMessage = (props: InferProps<typeof errorMessagePropTypes>): JSX.Element => {
	const { appDispatch } = useContext(AppContext)

	// -2 is a special 'error' type, indicating `prepare` has just been run.
	if (props.status === -2) {
		return (
			<div className='error error-new-data error-clickable'>
				<FontAwesomeIcon icon='plane-arrival' />
				<h3>New data available</h3>
				<div className='tip'>
					The information shown here is out of date.{'\n'}
					Click here to delete the store on your computer and begin to retrieve the updated information.
				</div>
			</div>
		)
	}

	let { action } = props
	if (typeof action === 'string') {
		try {
			action = JSON.parse(action)
		} catch(unusedError) {
			action = null
		}
	}

	const status = typeof props.status === 'number'
		? (props.status === -1
			? 'Failed to connect'
			: props.status === 401 ? 'Login session expired'
				: props.status + ' ' + HttpStatus.getStatusText(props.status))
		: (props.status !== true ? props.status : null)

	const onClick = action
		? typeof(action) === 'function'
			? action
			: () => props.dispatch?.({ type: 'CLEAR_FAILED_ACTION', action })
		: () => { /* do nothing */ }
	return (
		<div className={'error' + (action ? ' error-clickable' : '')} onClick={onClick}>
			<FontAwesomeIcon icon='heart-broken'/>
			{ status && <h3>{status}</h3> }
			{ props.status !== 401 && <div>An error occurred!</div> }
			{ !!action && <div>Click here to try again.</div>}
			{ !!props.isAuth0 && <div>Auth0 broke</div> }
			{ !props.status && <div className='tip'>
				Please get in touch with the Trove development team{ !props.error?.message ? '.'
					: ' and provide them with the following information:'
				}</div> }
			{ props.status === 401 && <div className='tip'>
				You were logged out due to inactivity, but are being logged back in automatically.
				Try again in a few moments.
			</div> }
			{ props.status === -1 && <div className='tip'>
				Unable to request data. The server may be down, or you may not be connected to the Internet.{'\n'}
				Try again in a few moments.
			</div> }
			{ !!props.error && !!props.error.message &&
			<div className='tip'>{props.error.message}</div> }
			{!props.status && <div className='tip'>
				Try reloading the page to resolve the issue.
				If that does not help, consider logging out
				<FontAwesomeIcon icon='sign-out-alt' onClick={() => appDispatch({ type: 'LOG_OUT' })} className='logout-icon'/>
				and back in.</div>}
		</div>
	)
}

const errorMessagePropTypes = {
	status: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	dispatch: PropTypes.func,
	action: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string,
		PropTypes.shape({
			type: PropTypes.string.isRequired
		})
	]),
	error: PropTypes.shape({
		message: PropTypes.string
	}),
	isAuth0: PropTypes.bool
}
ErrorMessage.propTypes = errorMessagePropTypes

export const EmptySearchError = (props: { message?: string }): JSX.Element =>
	<div className='error error-new-data error-clickable'>
		<FontAwesomeIcon icon='meh' />
		<h3>{props.message || 'Your search returned no results'}</h3>
	</div>

export default ErrorMessage
