import { AppContextState, DispatchableAppContextProvided, GetJwt, ReceiveDataVersion } from '../contexts/AppContext.d'
import { DispatchToApi, ErrorHandler, SuccessHandler, SuperDispatch } from './dispatch.d'
import { AnyAction, ApiRequestFailedAction, Dispatch, PrimaryAction } from './action/Action.d'
import { getApiEndpointUrl } from '../helpers/environment'

/* Bespoke dispatch/reducer system explanation:
 *  - Contexts should create dispatchers using code in this file and a tailored 'super-dispatcher'
 *  - All context actions should then go to that customised dispatcher
 *  - API request should call dispatchToApi() from within the super-dispatcher
 *  - Once complete, the standard reducer will be invoked automatically
 *  - In addition, an API_REQUEST_SUCCEEDED or API_REQUEST_FAILED will follow any API request
 *  - Actions not involving the API should simply delegate directly to dispatch() from the super-dispatcher
 *  - Generic action processors, such as for infinite scrolling, can be found within context.tsx
 *  - EntryContext and PersonContext represent working examples of the above process
 */

/* eslint dot-notation: ['error', { allowPattern: 'message' }]  */

export const generateDispatchToApi = (
	appState: AppContextState,
	appDispatch: Dispatch<PrimaryAction>,
	getJwt: GetJwt,
	defaultErrorHandler: ErrorHandler,
	defaultSuccessHandler: SuccessHandler
): DispatchToApi => async (path, {
	headers = {},
	body = {},
	method = 'GET',
	onError = defaultErrorHandler,
	onSuccess = defaultSuccessHandler
} = {}) => {
	const [tokenOrErrorMessage, triggerLogout] = await wrapGetJwt(getJwt)
	if (triggerLogout !== undefined) {
		console.error(tokenOrErrorMessage + (triggerLogout ? ' - logging you out' : ''))
		if (triggerLogout) {
			appDispatch({ type: 'LOG_OUT' })
		} else {
			appDispatch({ type: 'FLAG_LOGIN_ERROR', error: tokenOrErrorMessage })
			onError(tokenOrErrorMessage, { status: -8, ok: false } as Response, path)
		}
		return
	} else if (appState.user?.loginProblemDetected) {
		appDispatch({ type: 'FLAG_LOGIN_ERROR', error: false })
	}
	headers.Authorization =  `Bearer ${tokenOrErrorMessage}`
	if (!appState.disableAuth) {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		headers = { ...headers, EnableAuth: 'true' }
	}

	let bodyString: string | undefined = undefined
	if (typeof body === 'string' && !!body) {
		bodyString = body
		headers = { 'Content-Type': 'text/plain', ...headers }
	} else if (typeof body === 'object' && Object.keys(body).length > 0) {
		if (typeof body === 'string' && !('Content-Type' in headers))
			throw new TypeError('Body provided but no Content-Type header specified')
		else if (typeof body !== 'string')
			headers = { ...headers, 'Content-Type': 'application/json' }
		bodyString = typeof body == 'string'
			? body
			: JSON.stringify(body)
	}
	const userSuffix = !appState.user?.userOverride
		? ''
		: `${path.includes('?') ? '&' : '?'}user=${encodeURIComponent(appState.user.userOverride)}`
	const url = `${getApiEndpointUrl()}/${path[0] === '/' ? path.substring(1) : path}${userSuffix}`
	const fetchOptions: RequestInit = { body: bodyString, method, headers: { ...headers } }

	try {
		const response = await fetch(url, fetchOptions)
		const responseText = await getResponseContent(response)

		if (appState.offlineMode) {
			appDispatch({ type: 'TOGGLE_OFFLINE_MODE', offline: false })
		}
		if (response.status >= 200 && response.status < 300) {
			return onSuccess(responseText, response, path)
		}
		onError(responseText, response, path)
	} catch(e) {
		if (!appState.offlineMode) {
			appDispatch({ type: 'TOGGLE_OFFLINE_MODE', offline: true })
		}
		return onError(typeof e === 'object' ? String(e?.['message']) : String(e), { status: -1, ok: false } as Response, path)
	}
}

/** Return the body as a string, either directly or by base64 encoding binary data */
const getResponseContent = async (response: Response): Promise<string> => {
	const contentType = response.headers.get('Content-Type')
	if (contentType == 'image/png') {
		try {
			const responseArrayBuffer = await response.arrayBuffer()
			return Buffer.from(responseArrayBuffer).toString('base64')
		} catch(unusedError) {
			console.error(`Failed to decode ${contentType} body`)
			return 'N/A'
		}
	}
	return response.text()
}

/** Request a JWT from Auth0, and curate any errors that might be thrown */
const wrapGetJwt = async (getJwt: GetJwt):
	Promise<[token: string] | [errorMessage: string, triggerLogout: boolean]> => {
	try {
		const token = await getJwt()
		return [token]
	} catch(e) {
		if (typeof e !== 'object' || !e || !('message' in e)) {
			return  ['Unknown authentication error object: ' + JSON.stringify(e), false]
		}
		switch (e['message']) {
		case 'Login required':
			return ['Login session expired', false]
		case 'Get access token failed':
			return ['Internet disconnected?', false]
		default:
			return ['Unknown authentication error: ' + String(e['message']), false]
		}
	}
}

const MAX_FAILURE_CHAIN = 10
/** Convert a failed or not-started API response to an API_REQUEST_FAILED action */
export const generateDefaultErrorHandler = (
	dispatch: Dispatch<AnyAction>,
	action: PrimaryAction,
	redispatch: Dispatch<PrimaryAction>
): ErrorHandler =>
	(responseText, response, path) => {
		const failedAction: ApiRequestFailedAction = {
			type: 'API_REQUEST_FAILED',
			status: response.status,
			apiResponse: undefined,
			sourceAction: action,
			apiPath: path,
			responseText,
			redispatch
		}
		dispatch(failedAction)
		// If this action was initiated by another, notify for all in the chain.
		for (let counter = 1; action.initiatorAction; counter++) {
			if (counter >= MAX_FAILURE_CHAIN) {
				console.error('Gave up chaining action failures: max exceeded')
				break
			}
			action = action.initiatorAction
			dispatch({
				...failedAction,
				sourceAction: action
			})
		}
	}

/** Convert a low-level API response to an ApiResponseAction and an API_REQUEST_SUCCEEDED */
export const generateDefaultSuccessHandler = (
	dispatch: Dispatch<AnyAction>, redispatch: Dispatch<PrimaryAction>, action: PrimaryAction,
	receiveDataVersion?: ReceiveDataVersion
): SuccessHandler =>
	(responseText, response, path) => {
		let responseValue: string | Record<string, unknown> = responseText
		if (response.status === 204) {
			responseValue = {}
		} else {
			try {
				responseValue = JSON.parse(responseValue)
			} catch(unusedError) { /* data isn't JSON: return as is */ }
		}
		dispatch({
			redispatch,
			apiPath: path,
			type: action.type,
			sourceAction: action,
			status: response.status,
			apiResponse: typeof responseValue === 'string'
				? { data: responseValue }
				: responseValue,
			receiveDataVersion // AppContext
		})
		dispatch({
			type: 'API_REQUEST_SUCCEEDED',
			apiResponse: undefined,
			sourceAction: action,
			apiPath: path,
			redispatch
		})
	}

/** Top-level generator of customised dispatch() functions */
export const generateDispatcher = (
	dispatch: React.Dispatch<AnyAction>,
	superDispatch: SuperDispatch,
	appContextProvided: DispatchableAppContextProvided,
	dummyDispatchToApi?: jest.Mock
): Dispatch<PrimaryAction> => {
	const { appState, appDispatch, getJwt, receiveDataVersion } = appContextProvided
	return (action: PrimaryAction) => {
		const redispatch: Dispatch<PrimaryAction> = (newAction) =>
			// Allow success/error handlers to actually dispatch new actions.
			generateDispatcher(dispatch, superDispatch, appContextProvided)(
				// Keep track of the original action, unless that action said not to.
				action.noRelay ? newAction : { ...newAction, initiatorAction: action }
			)

		const errorHandler = generateDefaultErrorHandler(dispatch, action, redispatch)

		const successHandler = generateDefaultSuccessHandler(dispatch, redispatch, action, receiveDataVersion)

		const dispatchToApi: DispatchToApi = dummyDispatchToApi
			? dummyDispatchToApi
			: generateDispatchToApi(appState, appDispatch, getJwt, errorHandler, successHandler)

		void superDispatch(action, dispatch, dispatchToApi)
	}
}
