import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SettingsIcon from './SettingsIcon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { SettingsContext } from '../contexts/SettingsContext'
import { formatTimeIntervalBetween, formatTimeInterval, formatTimestamp, formatDateTime } from '../helpers/format'
import { faCog, faExclamationTriangle, faCheckCircle, faRocket, faHistory, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { PrepareLog, STATUS_ICON_LOADING, STATUS_PREPARE_PENDING } from '../contexts/SettingsContext.d'
import { actionInProgress } from '../helpers/action/action'
library.add(faCog, faExclamationTriangle, faCheckCircle, faRocket, faHistory, faStopwatch)

const logStreamLink = (stream: PrepareLog, admin: boolean | undefined, contents: JSX.Element | string = 'Log stream') =>
	!admin ? contents :
		<a target='_blank' title='View this log stream in full' rel='noopener noreferrer' href={'https://' + stream.region +
		'.console.aws.amazon.com/cloudwatch/home?region=' + stream.region +
		'#logEventViewer:group=' + encodeURI(stream.logGroupName) +
		';stream=' + encodeURI(stream.logStreamName)}>{contents}</a>

const Prepare = (props: { admin?: boolean }): JSX.Element => {
	const { state } = useContext(SettingsContext)

	const secondsRemaining =
		(state.prepare?.currentLog?.estimatedCompletionTime || 0) - (new Date().getTime() / 1000)
	const timeRemaining = secondsRemaining > 0
		? formatTimeInterval(secondsRemaining)
		: (state.prepare?.latestSuccessfulLog?.isComplete ? 'not long now...' : 'unknown')

	const status = actionInProgress({ type: 'CHECK_PREPARE_STATUS', instanceId: 'prepare' }, state)
		|| actionInProgress({ type: 'TRIGGER_PREPARE', instanceId: 'prepare' }, state)
		? STATUS_ICON_LOADING : state.instances?.prepare
	return (
		<div className='thumbnail thumbnail-static'>
			{ props.admin && <h3 className='pre'>prepare</h3> }
			{ !props.admin && <h3>Process your data</h3> }
			{ !props.admin && <div className='tip'>
				After writing or updating entries, you can trigger extraction of
				features like names and times. These can then be viewed,
				graphed and turned into wordclouds.<br/>
				Note: data processing takes at least one full minute.
			</div>}
			<div>
				<h4>Current status</h4>
				<SettingsIcon className='status' icon={status} />
				{ (state.prepare?.runningTasks || [])?.length > 1 &&
					<div className='clarification'>&times;{(state.prepare?.runningTasks || []).length}</div> }
				<SettingsIcon icon='sync-alt'
					action={{ type: 'CHECK_PREPARE_STATUS', instanceId: 'prepare' }} />
				{ state.prepare?.latestSuccessfulLog?.createdAt &&
					<>
						<div className='separator' />
						{logStreamLink(state.prepare?.latestSuccessfulLog, props.admin,
							<SettingsIcon icon='history' className='enabled' />)}
						<div className='clarification'>
							<div title={'Completed at ' + formatDateTime(state.prepare?.latestSuccessfulLog.latestEvent) + '.'}>
								{formatTimeIntervalBetween(state.prepare?.latestSuccessfulLog.latestEvent)} ago
							</div>
							<div>Took {formatTimeInterval(state.prepare?.latestSuccessfulLog.duration)}</div>
						</div>
					</>
				}
				{ (props.admin || !state.prepare?.runningTasks?.length) && <>
					<div className='separator' />
					{ !props.admin && 'Trigger now: '}
					<SettingsIcon icon="rocket"
						action={{ type: 'TRIGGER_PREPARE', instanceId: 'prepare' }}/>
				</> }
				{ (!props.admin && !!state.prepare?.runningTasks?.length) && <>
					<div className='separator' />
					<SettingsIcon icon="stopwatch" /> Remaining: {timeRemaining}
				</>}
			</div>
			{ (props.admin && (state.instances.prepare === STATUS_PREPARE_PENDING || (state.prepare?.runningTasks || []).length > 0)) &&
				<>
					<h4>Current log stream</h4>
					{(state.prepare?.currentLog?.isComplete === false &&
					<>
						<div>Remaining: <span className='tip'>{timeRemaining}</span></div>
						<div>Running for: <span className='tip'>{formatTimeInterval(state.prepare?.currentLog.duration)}</span></div>
						<div>Initiated at: <span className='tip'>{formatTimestamp(state.prepare?.currentLog.createdAt)}</span></div>
						<div>{logStreamLink(state.prepare?.currentLog, props.admin)}:</div>
						<div className='pre'>{state.prepare?.currentLog.events?.slice(-3).join('\n')}</div>
					</>) || <div className='tip'>Waiting for log stream...</div>
					}
				</>
			}
			{ props.admin && state.prepare?.latestCompleteLog?.createdAt && (
				(state.prepare?.latestCompleteLog?.failed ||
					(state.prepare?.latestStoppedTask?.taskArn &&
						state.prepare?.latestStoppedTask?.exitCode !== 0))) &&
				<>
					<h4>Latest failure log</h4>
					{ !state.prepare?.latestCompleteLog.failed &&
						<div className='warning note'>
							<SettingsIcon icon='exclamation-triangle'/>
							Warning: lambda was unable to detect failure in the log stream. You may want to fix this.
						</div>
					}
					<div>Failed at: <span className='tip'>{formatDateTime(state.prepare?.latestCompleteLog.latestEvent)}</span></div>
					<div>Ran for: <span className='tip'>{formatTimeInterval(state.prepare?.latestCompleteLog.duration)}</span></div>
					<div>{logStreamLink(state.prepare?.latestCompleteLog, props.admin)}:</div>
					<div className='pre'>{state.prepare?.latestCompleteLog.events?.slice(-10)?.join('\n')}</div>
				</>
			}
			{ props.admin && state.prepare?.latestStoppedTask?.failed &&
				<>
					<h4>Latest failed task</h4>
					<div>Failed at: <span className='tip'>{formatDateTime(state.prepare?.latestStoppedTask.stoppedAt)}</span></div>
					<div>Status: <span className='tip pre'>{state.prepare?.latestStoppedTask.stoppedReason}</span></div>
					{ !!state.prepare?.latestStoppedTask.exitCode &&
						<div>Exit code: <span className='tip pre'>{state.prepare?.latestStoppedTask.exitCode}</span></div> }
					{ !!state.prepare?.latestStoppedTask.statusReason &&
						<div>Reason: <span className='tip pre'>{state.prepare?.latestStoppedTask.statusReason}</span></div> }
				</>
			}
		</div>
	)
}
Prepare.propTypes = {
	admin: PropTypes.bool
}

export default Prepare
