/* eslint-disable max-len */

const about = `### What is Trove?

Trove allows you to write diary entries, then uses them to provide insights about your life.

### How can I use it?

Once you create a _free_ account, you will be able to write entries. These can be short or long, and can be about events, feelings, goals, ideas... the choice is yours!

The main focus of Trove's analysis is on \`@handles\` and \`#hashtags\`. These are extracted from entries' text during _processing_, which takes place every week automatically or can be initiated at will from the Settings page. After processing, details will become available such as links between ones which occur in the same entries.

### What does that look like?

You can have a look at the sorts of analysis available by exploring our sample account, based on _[The Little Prince](/about/account/little-prince)_ by Antoine de Saint-Exupéry.

### What if I want more?

Trove supports a number of [power-user features](/about/more), in case hashtags and handles are insufficient.

### I found a bug! What do I do?

Trove is still in early development, so there will be many rough edges around the site. If you see something not working, or simply have feedback on an existing feature or an idea for a future one, do not hesitate to get in touch at _trove_ dot _diary_ at _gmail_ dot _com_.

### What happens to my data? Is it safe?

Your data will be stored securely as per our [privacy policy](/about/privacy).
`
export default about
