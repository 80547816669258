import React, { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret, faSignOutAlt)

function NewUser(): JSX.Element {
	const { appDispatch } = useContext(AppContext)
	return (
		<div className='error'>
			<FontAwesomeIcon icon='user-secret' />
			<h3>Email address unverified</h3>
			<div className='tip'>
				Your account has not yet been set up.{'\n'}
				Please verify your email address then log in again.
			</div>
			<FontAwesomeIcon icon='sign-out-alt' onClick={() => appDispatch({ type: 'LOG_OUT' })} className='logout-icon'/>
		</div>
	)
}

export default NewUser
