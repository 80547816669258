import React, { useContext, MouseEventHandler } from 'react'
import { AppContext } from '../contexts/AppContext'
import { useAuth0 } from '@auth0/auth0-react'
import { getServerRoot } from '../helpers/environment'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import config from '../common/auth_config.json'
import { Link } from 'react-router-dom'
library.add(faSpinner)

const Login = (): JSX.Element =>
	<div className='login'>
		<h2>Welcome to Trove</h2>
		<h3>Understand your life</h3>

		<img src='/images/logo.svg' alt='Trove' />

		<LoginButton />

		{process.env.NODE_ENV === 'development' && <ToggleDisableAuth />}
		<Footer />
	</div>

const Footer = (): JSX.Element =>
	<div id='footer'>
		<Link to='/about'>About</Link> &bull; <Link to='/about/privacy'>Privacy policy</Link>
	</div>

const ToggleDisableAuth = (): JSX.Element => {
	const { appDispatch } = useContext(AppContext)
	const setUser = (user = undefined) => appDispatch({ type: 'SKIP_AUTH_TO_USER', user })
	return <div className='testing-skip-auth'>
		[Testing only:{' '}
		<button onClick={() => setUser() }>Disable authentication</button>]
	</div>
}

const LoginButton = (): JSX.Element => {
	const { appDispatch } = useContext(AppContext)
	const { loginWithRedirect, isLoading } = useAuth0()
	const serverRoot = getServerRoot()
	const onClick: MouseEventHandler<HTMLButtonElement> = () => {
		appDispatch({ type: 'UPDATE_LOGIN_STATUS', status: 'Logging in...' })
		void loginWithRedirect({ authorizationParams: {
			...config.credentials.authorizationParams,
			redirect_uri: serverRoot
		} })
	}
	return <div>
		<button disabled={isLoading} onClick={onClick}>
			Log in or create account
		</button>
		{ isLoading && <FontAwesomeIcon icon='spinner' spin={true} /> }
	</div>
}

export default Login
