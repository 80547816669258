import React, { useContext } from 'react'
import NavBarButton from './NavBarButton'
import { AppContext } from '../contexts/AppContext'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCog, faHammer, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faUserCog, faHammer, faInfoCircle)

const SectionNavBarSettings = (): JSX.Element => {
	const { hasLoginScope, isUserType } = useContext(AppContext)

	return <>
		{ !isUserType('public') && <NavBarButton text='Settings' icon='user-cog' target='/settings' /> }
		{ hasLoginScope('admin') && <NavBarButton text='Admin' icon='hammer' flip='horizontal' target='/admin' /> }
		<NavBarButton text='Help' icon='info-circle' target='/about' />
	</>
}

export default SectionNavBarSettings
