import React, { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import UserNavBar, { UserNavBarToggleButton } from './UserNavBar'
import { faBars, faCalendarWeek, faChartBar, faListOl, faMagnifyingGlass, faPenFancy } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import NavBarButton from './NavBarButton'
import { isUsingField } from '../contexts/app/auth'
import NavNotificationBar from './NavNotificationBar'
library.add(faBars, faPenFancy, faChartBar, faListOl, faCalendarWeek, faMagnifyingGlass)

const NavBar = (): JSX.Element =>
	<div id='nav-bar-wrapper'>
		<div id='nav-bar'>
			<UbiquitousNavBar />
			<ToggledNavBars />
		</div>
	</div>

const ToggledNavBars = (): JSX.Element => {
	const { appState } = useContext(AppContext)
	return (
		<div id='nav-and-notification-bar-wrapper'>
			<NavNotificationBar />
			<div id='toggled-nav-bar-wrapper'>
				<div id='user-nav-bar'
					className={'toggled-nav-bar' + (appState.menuShown === 'user-nav-bar' ? ' toggled' : '')}>
					<UserNavBar />
				</div>
			</div>
		</div>)
}

const UbiquitousNavBar = (): JSX.Element => {
	const { appState } = useContext(AppContext)
	return <div id='ubiquitous-nav-bar'>
		<NavBarButton icon='pen-fancy' target='/write' isOnPageNavBar={true} default={true} text='Write' />
		<NavBarButton icon='list-ol' target='/explore' isOnPageNavBar={true} text='Explore' />
		{ isUsingField('type', appState) && <NavBarButton icon='calendar-week' target='/spans' text='Spans' /> }
		<NavBarButton icon='magnifying-glass' target='/search' isOnPageNavBar={true} text='Search' />
		<UserNavBarToggleButton />
	</div>
}

export default NavBar
