import React from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faSpinner)

const Loading = (props: { message?: string }): JSX.Element =>
	<div className='loading' role="alertdialog" aria-busy="true">
		<FontAwesomeIcon icon='spinner' spin/>
		{props.message && <h3>{props.message}</h3>}
	</div>

Loading.propTypes = {
	message: PropTypes.string
}

export default Loading
