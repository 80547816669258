import { Dispatch, PrimaryAction, SetInProgressAction } from './Action.d'

/* Settings actions */

export const SETTINGS_INSTANCE_IDS = [
	'diary_production', 'diary_flash',
	'gocd_production', 'gocd_testing', 'prepare',
	'diary_production_db', 'diary_flash_db'] as const
export type SettingsInstanceId = typeof SETTINGS_INSTANCE_IDS[number]
export const SETTINGS_SYNC_APIS = ['fitbit', 'rescuetime'] as const
export type SettingsSyncApi = typeof SETTINGS_SYNC_APIS[number]

type CheckPrepareStatusAction = {
	type: 'CHECK_PREPARE_STATUS',
	instanceId: SettingsInstanceId
	interval?: string
}
type CheckInstanceStateAction = {
	type: 'CHECK_INSTANCE_STATE',
	instanceId: SettingsInstanceId
	interval?: string
}
type ResetInstanceStatusAction = {
	type: 'RESET_INSTANCE_STATUS',
	instanceId: SettingsInstanceId
}
export type CheckInstanceAction = CheckPrepareStatusAction | CheckInstanceStateAction
type SetWindowFocusedAction = {
	type: 'SET_WINDOW_FOCUSED',
	focused: boolean
}
type TriggerPrepareAction = {
	type: 'TRIGGER_PREPARE'
	instanceId: SettingsInstanceId
}
type ToggleInstanceAction = {
	type: 'TURN_INSTANCE_ON' | 'TURN_INSTANCE_OFF'
	instanceId: SettingsInstanceId
}
type GetSyncLoginUrlAction = {
	type: 'GET_SYNC_LOGIN_URL'
	api: 'fitbit'
}
export type SyncFitbitAuthCallbackAction = {
	type: 'SYNC_FITBIT_AUTH_CALLBACK'
	api: 'fitbit'
	code: string
	appDispatch: Dispatch
}
type UnlinkSyncApiAction = {
	type: 'UNLINK_SYNC_API'
	api: SettingsSyncApi
	appDispatch: Dispatch<PrimaryAction>
}
type TriggerSyncAction = {
	type: 'TRIGGER_SYNC'
	api: SettingsSyncApi
}
type SyncCallInitiatedAction = {
	type: 'SYNC_CALL_INITIATED'
	api: SettingsSyncApi
	request?: 'authUrl'
}
export type SettingsAction = CheckInstanceAction | ResetInstanceStatusAction |
	TriggerPrepareAction | ToggleInstanceAction | SetWindowFocusedAction |
	GetSyncLoginUrlAction | UnlinkSyncApiAction | TriggerSyncAction |
	SyncCallInitiatedAction | SyncFitbitAuthCallbackAction | SetInProgressAction
