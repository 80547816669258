
export const ENTRY_TYPE_NORMAL = 42
export const ENTRY_TYPE_SPAN = 40
/** @deprecated legacy entries only */
export const ENTRY_TYPE_SEJOUR_PLUS = 39

/** Number of seconds to wait between typing and autosaving */
export const AUTOSAVE_INTERVAL_NORMAL = 1.5
/** Wait a relatively short time before retrying a failed request */
export const AUTOSAVE_INTERVAL_FAILED = 1
/** A break in typing of this many seconds stops the 'exact' timer count */
export const TIMER_LIMIT_EXACT = 3
/** A break in typing of this many seconds stops the 'total' timer count */
export const TIMER_LIMIT_TOTAL = 3600

/** Hour of the (next) morning when a new entry should be automatically started */
export const TOMORROW_STARTS_AT = 6

/** How many characters can be in an entry before it's shortened when not active */
export const MAX_ENTRY_PREVIEW = 40

/** Entries with modified dates within this many hours of now will always show form fields, rather than the 'read' view. */
export const AUTO_EDIT_RECENT_ENTRIES = 36
