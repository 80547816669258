import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDayInterval, formatDayIntervalBetween, formatDate, formatTime, outputTime, dateOffset } from '../helpers/format'
import { FlipProp, IconProp } from '@fortawesome/fontawesome-svg-core'

type StatisticValue = string | number | boolean | undefined | null
type StatisticUnit = 'count' | 'interval' | 'frequency' | 'days_ago' | 'days_duration' | 'date' | 'percent' | 'time' | undefined
export type StatisticProps = {
	icon: IconProp
	name: string
	link?: string
	value?: StatisticValue
	units?: StatisticUnit
	prefix?: string
	suffix?: string
	flip?: FlipProp
}

const tidyValue = (value: StatisticProps['value'], units: StatisticUnit): StatisticValue => {
	if (typeof value === 'number' && !Number.isInteger(value)) { value = +value.toFixed(2) }
	switch (units) {
	case 'time':
		return formatTime(value?.toString()) || undefined
	case 'days_ago':
	case 'date':
		return formatDate(dateishValue(value))
	default:
		return value
	}
}

const dateishValue = (value: StatisticValue): string | number | undefined =>
	typeof value === 'boolean' ? Number(value) : value || undefined
const numberValue = (value: StatisticValue): number =>
	typeof value !== 'number' ? 0 : value

const formatForOutput = (value: StatisticProps['value'], units: StatisticUnit): JSX.Element | string | undefined => {
	if (typeof value === 'number' && !Number.isInteger(value)) { value = +value.toFixed(2) }
	switch (units) {
	case 'count':
		return '×' + value
	case 'interval':
		return formatDayInterval(numberValue(value))
	case 'frequency':
		return value + '/d'
	case 'days_ago':
		return formatDayIntervalBetween(dateishValue(value) || new Date())
	case 'days_duration':
		return '+' + dateOffset(typeof value !== 'string' ? value?.toString() : value)
	case 'date':
		return formatDate(dateishValue(value), 'short')
	case 'time':
		return outputTime(value ? value?.toString() : '')
	case 'percent':
		return (typeof value == 'number' ? Math.round(value * 100) : value) + '%'
	default:
		return typeof value === 'boolean' ? <></>
			: (Number.isNaN(value)
				? value?.toString()
				: value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
	}
}

const Statistic = (props: StatisticProps): JSX.Element => {
	const location = useLocation().pathname
	if (!props.value) { return <></> }
	const linkValue = tidyValue(props.value, props.units)
	const text = <>{formatForOutput(props.value, props.units)}{ props.suffix && <> {props.suffix}</> }</>
	const linkTarget = props.link && linkValue ? props.link.toString().replace(':value', linkValue?.toString()) : null
	const output = props.link && linkTarget
		? (location === linkTarget
			? <span className='statistic-link-current'>{text}</span>
			: <Link to={linkTarget}>{text}</Link>)
		: text
	return (
		<span className='statistic' title={props.name}>
			{ props.prefix && (
				linkTarget
					? <Link to={linkTarget}>{props.prefix} </Link>
					: props.prefix + ' ') }
			<FontAwesomeIcon icon={props.icon} flip={props.flip}/> {output}
		</span>
	)
}

const statisticPropTypes = {
	icon: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	link: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	units: PropTypes.oneOf(['date', 'percent', 'time', 'days_ago', 'days_duration', 'count', 'interval', 'frequency']),
	prefix: PropTypes.string,
	suffix: PropTypes.string,
	flip: PropTypes.string
}
Statistic.propTypes = statisticPropTypes

export default Statistic
