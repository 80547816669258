import { AppContextState, GetJwt, LoginScope, SpecialUserType, UserStateCheckerGenerator } from '../AppContext.d'
import { Auth0ContextInterface } from '@auth0/auth0-react'
import config from '../../common/auth_config.json'

/** Generate a function to query Auth0 for the user's JWT */
const retrieveJwt = (auth0: Auth0ContextInterface): GetJwt =>
	// eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-misused-promises
	() => new Promise(async (resolve, reject) => {
		setTimeout(() => reject(Error('getJwt() timed out: was this call indirectly' +
			' initiated from first render before Auth0 was initialised?')), 10000)
		try {
			const response = await auth0.getAccessTokenSilently({
				authorizationParams: config.credentials.authorizationParams
			})
			if (typeof response === 'string')
				resolve(response)
			reject(Error('JWT is not a string'))
		} catch(e) {
			reject(e as Error)
		}
	})

/** Generate a function to retrieve the user's JWT if needed */
export const getJwt = (appState: AppContextState, auth0: Auth0ContextInterface): GetJwt =>
	appState.user?.IS_SPECIAL_USER
	// eslint-disable-next-line @typescript-eslint/require-await
		? async () => appState.user?.jwt || 'DUMMY_JWT'
		: retrieveJwt(auth0)

/** Generate a function to allow components to easily check authentication status */
export const hasLoginScope: UserStateCheckerGenerator<LoginScope> = (appState, auth0) =>
	(scope = 'any') =>
		(scope === 'auth0' ? auth0?.isAuthenticated || appState.user?.IS_SPECIAL_USER :
			appState.user?.isAuthenticated && (
				scope === 'write'
					? !!appState.user?.row?.created && !isPublicUser(appState, auth0)()
					: (scope === 'any'
						|| (auth0?.user?.['trove:roles'] || appState.user?.simulatedRoles)?.includes(scope))))
		|| false

export const isUserType: UserStateCheckerGenerator<SpecialUserType> = (appState, auth0) => {
	const publicCheck = isPublicUser(appState, auth0)
	const powerCheck = isPowerUser(appState, auth0)
	return (type) => type == 'public' ? publicCheck() : powerCheck()
}

const isPublicUser: UserStateCheckerGenerator<'public'> = (appState, auth0) => () =>
	(auth0?.user?.sub || appState.user?.userOverride)?.startsWith('public|') || false

/* Whether or not to enable extra, mostly-unnecessary features */
const isPowerUser: UserStateCheckerGenerator<'power'> = (appState) => () =>
	appState.user?.row?.is_power_user || false

export const isUsingField = (field: string, appState: AppContextState): boolean =>
	!!appState.user?.row?.['write_' + field] || false
