import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import Statistic from '../common/Statistic'
import { ENTRY_TYPE_NORMAL, ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS } from '../Write/config'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSun, faBed, faStar, faBolt, faExclamation, faICursor, faUsers,
	faShoePrints, faHeartbeat, faMoneyBill, faCertificate, faCalendarWeek } from '@fortawesome/free-solid-svg-icons'
library.add(faSun, faBed, faStar, faBolt, faExclamation, faICursor, faUsers,
	faShoePrints, faHeartbeat, faMoneyBill, faCertificate, faCalendarWeek)

const EntryMetadata = (props: InferProps<typeof entryMetadatapropTypes>): JSX.Element => {
	return (
		<div className={props.className || undefined}>
			<Statistic
				icon="certificate"
				value={props.entry.type && ![ENTRY_TYPE_NORMAL, ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type)}
				link='/entries/with/type/exactly/:value'
				name='Big: this day was particularly special' />
			<Statistic icon="exclamation" value={props.entry.importance}
				link='/entries/with/importance/above/:value'
				name="Importance: a subjective measure of the importance of the day" />
			{ props.entry.startText !== '-' && <Statistic icon="sun"
				link='/entries/with/start/before/:value' units="time" value={props.entry.startText}
				name="Start: the 'start' time marked for this entry" /> }
			{ props.entry.type !== ENTRY_TYPE_NORMAL
				? <Statistic icon="calendar-week" units='days_duration' value={props.entry.endText}
					link={'/entries/with/type/exactly/' + props.entry.type}
					name='Span: this meta-entry summarises a period of this many days' />
				: <Statistic icon="bed" units="time" value={props.entry.endText}
					link='/entries/with/end/after/:value'
					name="End: the 'end' time marked for this entry" />
			}
			<Statistic icon="star" value={props.entry.rating}
				link='/entries/with/rating/above/:value'
				name="Rating: the rating given to this entry"/>
			<Statistic icon="money-bill"  value={props.entry.price}
				link='/entries/with/price/above/:value'
				name="Price: how much the events of this entry cost" />
			<Statistic icon="bolt" value={props.entry.energy}
				link='/entries/with/energy/above/:value'
				name="Energy: a subjective measure of mental competence" />
			<Statistic icon="i-cursor"  value={props.entry.wordcount}
				link='/entries/with/wordcount/above/:value'
				name="Wordcount: how many words appear in the entry and summary combined" />
			<Statistic icon="users"  value={props.entry.sociability}
				link='/entries/with/sociability/above/:value'
				name="Sociability: how many different people are mentioned in this entry" />
			<Statistic icon="shoe-prints" value={props.entry.steps}
				link='/entries/with/steps/above/:value'
				name="Steps: how many steps were recorded by a fitness tracker"/>
			<Statistic icon="heartbeat" value={props.entry.resting_heart_rate}
				link='/entries/with/resting_heart_rate/above/:value'
				name="Heartrate: resting heart rate during the day"/>
		</div>
	)
}

const entryMetadatapropTypes = {
	entry: PropTypes.shape({
		startText: PropTypes.string,
		endText: PropTypes.string,
		type: PropTypes.number,
		entry: PropTypes.string,
		rating: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([''])]),
		energy: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([''])]),
		importance: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([''])]),
		sociability: PropTypes.number,
		wordcount: PropTypes.number,
		steps: PropTypes.number,
		price: PropTypes.string,
		resting_heart_rate: PropTypes.number
	}).isRequired,
	className: PropTypes.string
}
EntryMetadata.propTypes = entryMetadatapropTypes

export default EntryMetadata
