import React from 'react'

export const toCamelCase = (str: string): string =>
	str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
		if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
		return index === 0 ? match.toLowerCase() : match.toUpperCase()
	}).replace(/[^a-z ]/ig, '')

export const formatNumberWithCommas = (num: number | null | undefined): string =>
	num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'

// Format a number of seconds as a number of y/w/d.
export const formatDayInterval = (interval: number | undefined): string => {
	if (interval === undefined) { return 'unknown' }
	if (interval <= 28) { return +(+interval).toFixed(1) + 'd' }

	const years = Math.floor(interval / 365)
	interval = interval % 365
	const weeks = Math.floor(interval / 7)
	interval = interval % 7
	const days = +interval.toFixed(1)

	let output = ''
	if (days !== 0) { output = days + 'd' + output }
	if (weeks !== 0) { output = weeks + 'w ' + output }
	if (years !== 0) { output = years + 'y ' + output }
	return output.endsWith(' ') ? output.slice(0, -1) : output
}

export type Dateish = Date | string | number

export const ONE_DAY = 60 * 60 * 24

export const onSameDay = (a: Dateish, b: Dateish): boolean =>
	formatDate(a) === formatDate(b)

// Calculate the number of milliseconds between two dates (or one date and now).
export const calculateIntervalBetween = (first: Dateish, second: Dateish = new Date()): number =>
	(new Date(second).getTime() - new Date(first).getTime()) / 1000

// Take two dates (or one date and assume today) and return y/w/d between them.
export const formatDayIntervalBetween = (first: Dateish, second: Dateish = new Date()): string =>
	formatDayInterval(
		Math.round(calculateIntervalBetween(first, second) / (60 * 60 * 24)))

export const formatTimeIntervalBetween = (first?: Dateish, second: Dateish = new Date()): string =>
	!first ? '' : formatTimeInterval(calculateIntervalBetween(first, second))

// Take a number of seconds between two times, and output two components of it.
export const formatTimeInterval = (interval = 0): string => {
	if (interval >= 60 * 60 * 24 * 7) { // y/w/d
		return formatDayInterval(Math.floor(interval / (60 * 60 * 24)))
	} else if (interval >= 60 * 60 * 24) { // d/h
		return Math.floor(interval / (60 * 60 * 24)) + 'd ' +
			Math.floor((interval % (60 * 60 * 24)) / 3600) + 'h'
	} else { // h/m/s
		return formatSubDayTimeInterval(interval)
	}
}

// Take a number of seconds between two times, and output hours, mins and/or secs.
const formatSubDayTimeInterval = (intervalSeconds: number): string => {
	let output = ''
	if (intervalSeconds >= 60 * 60 * 24) {
		output = formatDayInterval(intervalSeconds / (60 * 60 * 24)) + ' '
	}
	const ignoreSeconds = intervalSeconds >= 60 * 60
	const hours = Math.floor(intervalSeconds / (60 * 60))
	intervalSeconds = intervalSeconds % (60 * 60)
	const minutes = Math.floor(intervalSeconds / 60)
	intervalSeconds = intervalSeconds % 60
	const seconds = intervalSeconds < 10
		? Math.floor(intervalSeconds * 10) / 10
		: Math.floor(intervalSeconds)

	if (hours > 0) { output = output + hours + 'h' }
	if (minutes > 0) { output = output + minutes + 'm' }
	if (seconds > 0 && !ignoreSeconds) { output = output + seconds + 's' }

	return output
}

type DateFormat = 'unambiguous' | 'short' | 'long'

// Take anything the Date class recognises and return time and/or date appropriately.
export const formatDateTime = (timestamp: Dateish = new Date(), format: DateFormat = 'short', forceFullDate = false): string => {
	const date = formatDate(timestamp)
	if (!date) { return 'an unknown time' }
	const time = formatTimestamp(timestamp)
	if (!forceFullDate && date === formatDate(new Date())) { return time + ' today' }
	if (!forceFullDate && date === formatDate(new Date().getTime() - 1000 * 60 * 60 * 24)) { return time + ' yesterday' }
	return formatDate(timestamp, format) + ' ' + time
}

export const formatDateEnd = (endText: string | null | undefined, fromDate: string, format: DateFormat = 'unambiguous'): string =>
	(timeToSecs(endText) && formatDate(
		new Date(fromDate).getTime()
		+ (timeToSecs(endText) || 0) * 1000,
		format)
	) || ''

// Number of milliseconds in a HH:MM:SS time string.
export const parseHms = (time: string | number): number =>
	typeof time === 'number' ? time :
		(Number(time.substring(0, 2)) * 3600 +
	Number(time.substring(3, 5)) * 60 +
	Number(time.substring(6, 8))) * 1000

const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
	'Sep', 'Oct', 'Nov', 'Dec']
const monthNamesLong = ['January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December']

export const formatPage = (pageStart: Dateish = new Date(), pageEnd: string | undefined = undefined): number =>
	pageEnd
		? formatPage(pageStart) + Math.floor((timeToSecs(pageEnd) || 0) / (60 * 60 * 24))
		: (new Date(pageStart).getTime() / (60 * 60 * 24 * 1000)) + 1

/** Take anything the Date class recognises (default: now) and return YYYY-MM-DD. */
export const formatDate = (
	dateIn: Dateish = new Date(),
	format: DateFormat = 'unambiguous'
): string => {
	const date = new Date(dateIn)
	if (format === 'unambiguous') {
		return isNaN(date.getDate())
			? ''
			: date.getFullYear().toString().padStart(4, '0') + '-' +
			(date.getMonth() + 1).toString().padStart(2, '0') + '-' +
			date.getDate().toString().padStart(2, '0')
	}
	return date.getDate() + ' ' +
		(format === 'long' ? monthNamesLong : monthNamesShort)[date.getMonth()] + ' ' +
		(format === 'long' ? date.getFullYear() : "'" + date.getFullYear().toString().substring(2, 4))
}

// Take any expression the Date class can recognise, and return it as HH:MM:SS.
export const formatTimestamp = (timestamp: Dateish = new Date()): string => {
	const time = new Date(timestamp)
	return time.getHours().toString().padStart(2, '0') +
	':' + time.getMinutes().toString().padStart(2, '0') +
	':' + time.getSeconds().toString().padStart(2, '0')
}

// Get the day of the week for the given date.
export const formatDayOfWeek = (date: Dateish): string =>
	['Sunday', 'Monday', 'Tuesday', 'Wednesday',
		'Thursday', 'Friday', 'Saturday'][new Date(date).getDay()]

// Note that this must be contained within EntryContentText.js:regexTimeInText.
export const regexTime = /(~?)(([0-9]{2,})[: ]?([0-5][0-9]))(~?)(?![0-9])/

export const timeToSecs = (timeIn: string | null | undefined): number | null => {
	const match = timeIn?.toString().match(regexTime)
	if (!match) { return null }
	return Number(match[3]) * 3600 + Number(match[4]) * 60
}

// Format a time for internal use, e.g. parameters to send to the API.
export const formatTime = (timeIn?: string | Date): string | undefined | null => {
	const match = timeIn?.toString().match(regexTime)
	return match ? match[3] + ':' + match[4] : timeIn?.toString()
}

export const dateOffset = (timeIn?: string | Date): number =>
	Math.floor(parseInt(timeIn?.toString().match(regexTime)?.[3] || '') / 24)

// Format a time prettily for output, including superscripted +1s for >24h times.
export const outputTime = (timeIn: string, timeOnly = false): string | JSX.Element => {
	const match = timeIn?.toString().match(regexTime)
	let timeOut: (string | JSX.Element | undefined | null)[] = [formatTime(timeIn)]
	if (match === null) { return timeIn }

	const originalHour = Number(match[3])
	if (originalHour >= 24) {
		timeOut = [
			(originalHour % 24).toString().padStart(2, '0') + ':' + match[4],
			<span key='plus-n' className='day-plus-n'>+{Math.floor(originalHour / 24)}</span>
		]
	}
	if (match[1] || match[5]) {
		// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
		timeOut[0] = '~' + timeOut[0]
	}

	if (!timeOnly) {
		// Concatenate whatever text surrounded the matched time expression.
		timeOut = [
			timeIn.substring(0, match.index),
			...timeOut,
			match.index ? timeIn.substring(match.index + match[0].length) : null
		]
	}
	return <>{timeOut}</>
}
