import React, { useState, useContext } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ENTRY_TYPE_NORMAL, ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS } from './config'
import { SimpleEntryWriteComponent, SIMPLE_ENTRY_WRITE_PROPS, EntryWriteUpdateComponent, ENTRY_WRITE_UPDATE_PROPS } from './Write.d'
import { formatDate, calculateIntervalBetween, formatDateEnd } from '../helpers/format'
import { faCalendarWeek, faFileAlt, faSun, faBed, faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../contexts/AppContext'
import { isUsingField } from '../contexts/app/auth'
library.add(faCalendarWeek, faFileAlt, faSun, faBed, faCaretSquareDown, faCaretSquareUp)

const DateAndTimeBlock: EntryWriteUpdateComponent = (props): JSX.Element => {
	const { appState } = useContext(AppContext)
	const [dateEnd, setDateEnd] = useState('') // Allows temporarily invalid dates.
	if (!isUsingField('date', appState) && !isUsingField('type', appState)) {
		return <></>
	}
	return (
		<div className='entry-write-timing'>
			<div className='no-line-wrap'>
				{ isUsingField('type', appState) &&
				<EntryTypeField
					entry={props.entry} setValue={props.setValue}
					formUpdated={props.formUpdated} setDateEnd={setDateEnd} /> }
				{ isUsingField('date', appState) && <DateField entry={props.entry} setValue={props.setValue} /> }
			</div>

			{ (isUsingField('date', appState) || isUsingField('type', appState)) && <div className='no-line-wrap'>
				{ isUsingField('date', appState) && ![ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type || 0) &&
					<StartTextField entry={props.entry} setValue={props.setValue} />}

				{ [ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type || 0) && <>
					{ !isUsingField('date', appState) && <span className='tip'>{formatDate(props.entry.date, 'long')}</span>}
					<em>to</em>
					<DateEndField entry={props.entry} setValue={props.setValue}
						dateEnd={dateEnd} setDateEnd={setDateEnd} />
				</>}
			</div> }
			{ isUsingField('date', appState) && ![ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type || 0) &&
			<EndTextField entry={props.entry} setValue={props.setValue} />}
		</div>)
}
DateAndTimeBlock.propTypes = ENTRY_WRITE_UPDATE_PROPS

const EntryTypeField = (props: InferProps<typeof entryTypeFieldPropTypes>) => {
	const isSpan = [ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type || 0)
	const title = isSpan
		? 'Turn this back into a standard entry'
		: 'Mark that this entry spans a period of time'
	const icon = props.entry.type === ENTRY_TYPE_NORMAL ? 'file-alt' : 'calendar-week'
	const onClick = () => {
		props.formUpdated()
		props.setDateEnd('')
		props.setValue('type', isSpan ? ENTRY_TYPE_NORMAL : ENTRY_TYPE_SPAN)
	}
	return <FontAwesomeIcon className='entry-type' title={title} onClick={onClick} icon={icon} />
}
const entryTypeFieldPropTypes = {
	...SIMPLE_ENTRY_WRITE_PROPS,
	formUpdated: PropTypes.func.isRequired,
	setDateEnd: PropTypes.func.isRequired
}
EntryTypeField.propTypes = entryTypeFieldPropTypes

const DateField: SimpleEntryWriteComponent = (props) => {
	const [dateState, setDateState] = useState('') // Allows temporarily invalid dates.
	const date = dateState || formatDate(props.entry.date) || ''
	const setDate = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDateState(e.target.value)
		props.setValue('date', e.target.value)
	}
	return <input type='date' value={date} onChange={setDate} />
}
DateField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const DateEndField = (props: InferProps<typeof dateEndFieldPropTypes>) =>{
	const dateEnd = props.dateEnd || formatDateEnd(props.entry.endText, props.entry.date)
	const setDateEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.setDateEnd?.(e.target.value)
		const diff = calculateIntervalBetween(props.entry.date, e.target.value) / 3600
		props.setValue('endText', (diff > 0 ? diff : '00') + ':00')
	}
	return <input type='date' value={dateEnd} onChange={setDateEnd} />
}
const dateEndFieldPropTypes = {
	...SIMPLE_ENTRY_WRITE_PROPS,
	dateEnd: PropTypes.string,
	setDateEnd: PropTypes.func
}
DateEndField.propTypes = dateEndFieldPropTypes

const StartTextField: SimpleEntryWriteComponent = (props) => (<>
	<label htmlFor='startText'><FontAwesomeIcon icon='sun' /></label>
	<input id='startText' value={props.entry.startText || ''} placeholder='e.g. 08:15'
		onChange={e => props.setValue('startText', e.target.value) } />
</>)
StartTextField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const EndTextField: SimpleEntryWriteComponent = (props) => (
	<div className='no-line-wrap'>
		<em>to</em>
		<label htmlFor='endText'><FontAwesomeIcon icon='bed' /></label>
		<input id='endText' value={props.entry.endText || ''} placeholder='e.g. 21:30'
			onChange={e => props.setValue('endText', e.target.value) } />
	</div>)
EndTextField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

export default DateAndTimeBlock
