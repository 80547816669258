import { Reducer } from 'react'
import { CacheWriteEntryAction, EntryAction, InterimWriteEntryAction, SaveWriteEntryAction,
	MarkDeleteWriteEntryAction, SendDeleteWriteEntryAction } from '../helpers/action/EntryAction.d'
import { ApiResponseAction, Dispatch, LoadDetailsAction, LoadIdsAction } from '../helpers/action/Action.d'
import { areEntriesEqual, isEntryBlank, isInterimEntry, lastEntryOnDay } from './helpers'
import { EntryContextState } from '../contexts/EntryContext.d'
import { contextConfig } from '../contexts/EntryContext'
import { ENTRY_TYPE_SPAN, Entry, EntryId } from '../Entry/Entry.d'
import { formatDate } from '../helpers/format'
import { LOAD_DETAILS_ACTION_TYPES, LOAD_IDS_ACTION_TYPES } from '../helpers/action/action'

/** Forget which entry is currently being written, optionally replacing it with a blank one on the given date */
export const startNewEntry = (dispatch: Dispatch<EntryAction>, entryId?: number): void =>
	dispatch({ type: 'SELECT_WRITE_ENTRY', entryId })

export const initialiseInterimEntry = (dispatch: Dispatch<EntryAction>, date: Date): void =>
	dispatch({ type: 'INTERIM_WRITE_ENTRY', date: formatDate(date) })

export const getEntriesAction = (ids: number | number[]): LoadDetailsAction => ({
	type: 'LOAD_ENTRIES_BY_IDS',
	items: Array.isArray(ids) ? ids : [ids]
})

export const getEntriesActionKey = (ids: number | number[]): string =>
	JSON.stringify(getEntriesAction(ids))

export const getIdsAction = (date: Date | string): LoadIdsAction => ({
	type: 'QUERY_ENTRIES',
	field: 'date',
	comparison: 'exactly',
	value: typeof date === 'string' ? date.substring(0, 10) : formatDate(date, 'unambiguous')
} as const)

export const treatInterimWriteEntry: Reducer<EntryContextState, InterimWriteEntryAction> = (state, action) => {
	// Find the first unused interim (i.e. negative) entry ID
	let interimEntryId = -1
	for (; state.write?.[interimEntryId]; interimEntryId--);
	return {
		...state,
		write: {
			...state.write,
			[interimEntryId]: {
				id: interimEntryId,
				date: action.date,
				saveStatus: 'saved'
			}
		},
		currentlyWriting: interimEntryId
	}
}

export const treatCacheWriteEntry: Reducer<EntryContextState, CacheWriteEntryAction> = (state, action) => {
	const entry = state.write?.[action.entry.id] || state.entries?.[action.entry.id]
	if (!entry) {
		console.error('Caching details of an entry with no record in state')
		return state
	}
	if (state.entries?.[action.entry.id] && state.write?.[action.entry.id]
        && state.entries?.[action.entry.id]?.date !== state.write?.[action.entry.id]?.date) {
		state = removeIdFromQueries(state, action.entry.id, state.entries?.[action.entry.id].date)
		state.redirectTo = action.entry.id
	}
	const date = (action.entry.date as string) || entry.date
	const saveEntryAction = JSON.stringify({ type: 'SAVE_WRITE_ENTRY', entryId: entry.id })
	// If we entered some invalid information before, try again now we've updated the entry.
	if (state.failed?.[saveEntryAction] === 400) {
		delete state.failed?.[saveEntryAction]
	}
	return {
		...state,
		write: {
			...state.write,
			[action.entry.id]: {
				...entry,
				...action.entry,
				matches: undefined,
				date
			}
		}
	}
}

const idQueriesState: Reducer<EntryContextState, ApiResponseAction<SaveWriteEntryAction>>
= (state, action) => {

	const { failed } = state
	let { idsByQuery } = state
	const previousId = action.sourceAction.entryId
	const savedEntry = action.apiResponse?.data as Entry | undefined
	if (typeof savedEntry !== 'object'
		|| !savedEntry?.id
		|| typeof savedEntry.id !== 'number'
		|| typeof savedEntry.date !== 'string') {
		return { idsByQuery }
	}
	if (!idsByQuery) {
		idsByQuery = {}
	}
	const entryId: EntryId = savedEntry.id
	const date = new Date(savedEntry.date)

	if (previousId === entryId && 'status' in action && action.status === 201) {
		// After restoring a previously-deleted entry, we have no idea which queries it belonged to
		// - so simulate a page refresh in order to re-retrieve all entries by date.
		return {
			failed: {},
			actionStatus: {}
		}
	}

	const entriesByDateAction = JSON.stringify(getIdsAction(date))
	const newEntriesQueryAction = JSON.stringify({
		type: contextConfig.actionTypes.loadIds[0],
		field: 'status',
		value: 'new'
	})

	if (failed?.[entriesByDateAction] === 404) {
		delete failed[entriesByDateAction]
	}
	if (!idsByQuery[entriesByDateAction]) {
		idsByQuery[entriesByDateAction] = [entryId]
	} else if (isInterimEntry(previousId)) {
		idsByQuery[entriesByDateAction].push(entryId)
	}

	if (isInterimEntry(previousId) && newEntriesQueryAction in idsByQuery) {
		idsByQuery[newEntriesQueryAction].push(entryId)
	}
	return { idsByQuery, failed }
}

export const treatSaveWriteEntry: Reducer<EntryContextState, ApiResponseAction<SaveWriteEntryAction>> =
(state, action) => {
	const previousId = action.sourceAction.entryId
	const savedEntry = action.apiResponse?.data as Entry
	const entryId = savedEntry.id
	const writeEntry = state.write?.[previousId]

	const newWriteEntry: Entry = {
		...writeEntry,
		id: entryId,
		date: writeEntry?.date || savedEntry.date,
		saveStatus: 'waiting',
		modified: savedEntry.modified,
		status: savedEntry.status
	}
	savedEntry.writeup_time_exact = writeEntry?.writeup_time_exact
	savedEntry.writeup_time_total = writeEntry?.writeup_time_total
	state = {
		...state,
		...idQueriesState(state, action),
		entries: {
			...state.entries,
			[entryId]: savedEntry
		},
		write: {
			...state.write,
			[entryId]: newWriteEntry
		},
		redirectFrom: previousId !== entryId ? previousId : undefined,
		redirectTo: previousId !== entryId ? entryId : undefined,
		currentlyWriting: state.currentlyWriting === previousId ? entryId : state.currentlyWriting
	}
	// If we modify span entries, retrieve them all again next time we go to that page.
	const spansActionKey = JSON.stringify({ type: 'RETRIEVE_SPANS' })
	if ((savedEntry.type === ENTRY_TYPE_SPAN || writeEntry?.type === ENTRY_TYPE_SPAN)
        && state.inProgress && spansActionKey in state.inProgress) {
		delete state.inProgress[spansActionKey]
	}
	if (isInterimEntry(previousId)) {
		delete state.write?.[previousId]
	}
	if (!writeEntry || areEntriesEqual(writeEntry, savedEntry)) {
		delete state.write?.[entryId]
	}

	return state
}

/** Remove knowledge of the entry from any relevant idsByQuery */
export const removeIdFromQueries: (state: EntryContextState, entryId: EntryId, date?: Date | string) => EntryContextState =
(state, entryId, date) => {
	if (!state.idsByQuery) {
		return state
	}
	// Either remove from all queries, or just today's.
	const keys = date
		? [JSON.stringify(getIdsAction(date))]
		: Object.keys(state.idsByQuery)
	for (const key of keys) {
		state.idsByQuery[key] = state.idsByQuery[key]?.filter(id => id !== entryId)
		if (state.idsByQuery[key]?.length == 0) {
			delete state.idsByQuery[key]
			state.failed = {
				...state.failed,
				[key]: 404
			}
		}
	}
	return state
}

/** Additional Entry-specific reducer steps to take place alongside standard functionality */
export const extraEntryApiResponseReducer: Reducer<EntryContextState, ApiResponseAction> = (state, action) => {
	/** If we're modifying an entry but it's somehow vanished, clear it from state */
	if (LOAD_IDS_ACTION_TYPES.includes(action.type) && action.apiResponse
		&& state.idsByQuery && state.currentlyWriting && state.entries
		&& !(state.write && state.currentlyWriting in state.write)
		&& state.currentlyWriting in state.entries
		&& state.idsByQuery[JSON.stringify(action.sourceAction)]?.includes(state.currentlyWriting)
		&& Array.isArray(action.apiResponse.data)
		&& !action.apiResponse.data.includes(state.currentlyWriting)) {
		delete state.entries[state.currentlyWriting]
		delete state.currentlyWriting
		return { ...state }
	}

	// The rest of the function deals with entry detail retrieval, e.g. LOAD_ENTRIES_BY_IDS.
	if (!LOAD_DETAILS_ACTION_TYPES.includes(action.type) || !action.apiResponse
        || !state.currentlyWriting) { // and then only if we're modifying an entry.
		return state
	}
	const currentEntry = state.write?.[state.currentlyWriting] || state.entries?.[state.currentlyWriting]
	const returnedEntries = (Array.isArray(action.apiResponse.data) ? action.apiResponse.data : [action.apiResponse.data]) as Entry[]
	// Only proceed if we're currently modifying an entry.
	if (!currentEntry) {
		return state
	}

	// Stop default-modifying an entry if we've just learned it's been processed.
	if (currentEntry.status !== 'processed' && !state.write?.[state.currentlyWriting]
        && returnedEntries.some(entry => entry.id === currentEntry.id && entry.status === 'processed')) {
		delete state.currentlyWriting
		return { ...state }
	}

	// Delete an interim entry on a day where other entries have just been returned.
	if (isInterimEntry(state.currentlyWriting) && state.write && isEntryBlank(state.write[state.currentlyWriting])
        && returnedEntries.some(entry => formatDate(entry.date) == formatDate(currentEntry.date))) {
		delete state.write[state.currentlyWriting]
		delete state.currentlyWriting
		return { ...state }
	}
	return state
}

export const treatMarkDeleteWriteEntry: Reducer<EntryContextState, MarkDeleteWriteEntryAction> =
(state, action) => {
	const entry = state.write?.[action.entryId] || state.entries?.[action.entryId]
	if (!entry) {
		console.error(`No action taken: deleting an entry (${action.entryId}) which doesn't exist`)
		return state
	}
	delete state.inProgress?.[ JSON.stringify({ type: 'SAVE_WRITE_ENTRY', entryId: action.entryId }) ]
	if (isInterimEntry(action.entryId)) {
		const entryDate = state.write?.[action.entryId]?.date
		delete state.write?.[action.entryId]
		if (state.currentlyWriting === action.entryId) {
			state.currentlyWriting = lastEntryOnDay(state, entryDate)
		}
		return { ...state }
	}
	return {
		...state,
		write: {
			...state.write,
			[action.entryId]: {
				...entry,
				saveStatus: 'deleting'
			}
		}
	}
}

export const treatSendDeleteWriteEntry: Reducer<EntryContextState, ApiResponseAction<SendDeleteWriteEntryAction>> =
(state, action) => {
	const { entryId } = action.sourceAction
	delete state.entries?.[entryId]
	delete state.inProgress?.[JSON.stringify(action.sourceAction)]
	state = removeIdFromQueries(state, entryId)

	if (!state.write || !(entryId in state.write)) {
		return { ...state }
	}
	state = {
		...state,
		write: {
			...state.write,
			[entryId]: {
				...state.write[entryId],
				saveStatus: 'deleted'
			}
		}
	}
	return state
}
