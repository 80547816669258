import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ErrorMessage from '../common/ErrorMessage'

interface Props {
  children: JSX.Element;
}

interface State {
  error?: Error;
}

export default class ErrorBoundary extends Component<Props, State> {
	public state: State = {	}

	static getDerivedStateFromError(error: Error): State {
		// Update state so the next render will show the fallback UI.
		return { error }
	}

	componentDidCatch(): void {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	render(): JSX.Element {
		if (this.state.error) {
			return <ErrorMessage error={this.state.error} />
		}

		return this.props.children
	}

	static get propTypes(): unknown {
		return {
			children: PropTypes.node
		}
	}
}
