import React from 'react'
import Admin from './Admin'
import UserSettings from './UserSettings'
import { Route, Switch } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt, faCog, faQuestionCircle, faExclamationTriangle, faCheckCircle,
	faRocket, faHistory, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import SettingsContextProvider from '../contexts/SettingsContext'
library.add(faSyncAlt, faCog, faQuestionCircle, faExclamationTriangle, faCheckCircle,
	faRocket, faHistory, faExternalLinkAlt)

const SettingsPage = (): JSX.Element =>
	<SettingsContextProvider>
		<SettingsPageContents />
	</SettingsContextProvider>

const SettingsPageContents = (): JSX.Element =>
	<div className="page settings-page">
		<Switch>
			<Route path="/admin">
				<Admin />
			</Route>
			<Route>
				<UserSettings />
			</Route>
		</Switch>
	</div>

export default SettingsPage
