import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'
import DateAndTimeBlock from './DateAndTimeBlock'
import RatingsAndPriceBlock from './RatingsAndPriceBlock'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EntryField } from './Daily'
import { Entry } from '../Entry/Entry.d'
import { AppContext } from '../contexts/AppContext'
import { isUsingField } from '../contexts/app/auth'
import { authenticationFailed } from '../site/Login'
import { EntryContext } from '../contexts/EntryContext'
import { VoiceTranscript } from './VoiceRecord'
import { FormUpdated, SetValue, SimpleEntryWriteComponent, SIMPLE_ENTRY_WRITE_PROPS } from './Write.d'
import { faQuoteLeft, faListAlt, faMapMarkerAlt,
	faExclamationTriangle, faMicrophone, faCircleStop } from '@fortawesome/free-solid-svg-icons'
library.add(faQuoteLeft, faListAlt, faMapMarkerAlt, faExclamationTriangle, faMicrophone, faCircleStop)

const FormFields = (props: { entry: Entry, setValue: SetValue, formUpdated: FormUpdated }): JSX.Element => {
	// Track whether we're hiding most of the fields
	const { appState } = useContext(AppContext)
	const { isLoading, isAuthenticated } = useAuth0()
	const loginIssue = authenticationFailed(isLoading, isAuthenticated, appState)
	const fieldsetClass = loginIssue
		? 'form-warning' :
		appState.offlineMode
			? 'form-offline'
			: `entry-${props.entry.saveStatus || 'fresh'}`
	return <fieldset className={fieldsetClass} disabled={props.entry.status === 'deleted'}>

		{/* Title, location */}
		{ isUsingField('title', appState) && <TitleField entry={props.entry} setValue={props.setValue} /> }
		{ isUsingField('loc', appState) && <LocationField entry={props.entry} setValue={props.setValue} />}

		{/* Date(s), type, start time, end time */}
		<DateAndTimeBlock entry={props.entry} setValue={props.setValue} formUpdated={props.formUpdated} />

		{/* Ratings, price */}
		<RatingsAndPriceBlock entry={props.entry} setValue={props.setValue} />

		{ isUsingField('voice', appState) && <VoiceTranscript
			entry={props.entry} setValue={props.setValue} formUpdated={props.formUpdated} />}

		{/* Summary, entry */}
		{ isUsingField('summary', appState) && <SummaryField entry={props.entry} setValue={props.setValue} /> }

		<ModificationConflictMessage entry={props.entry} />
		<EntryField entry={props.entry} setValue={props.setValue} />
	</fieldset>
}
const formFieldsPropTypes = {
	...SIMPLE_ENTRY_WRITE_PROPS,
	formUpdated: PropTypes.func.isRequired
}
FormFields.prototypes = formFieldsPropTypes

const TitleField: SimpleEntryWriteComponent = (props) => (
	<div className='entry-write-descriptor'>
		<input id='title' value={props.entry.title || ''} placeholder='e.g. My first entry'
			onChange={e => props.setValue('title', e.target.value)} />
		<label htmlFor='title'><FontAwesomeIcon icon='quote-left' /></label>
	</div>)
TitleField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const LocationField: SimpleEntryWriteComponent = (props) => (
	<div className='entry-write-descriptor'>
		<input id='loc' value={props.entry.loc || ''} placeholder='e.g. London; Gare de Lyon, Paris'
			onChange={e => props.setValue('loc', e.target.value)} />
		<label htmlFor='loc'><FontAwesomeIcon icon='map-marker-alt' /></label>
	</div>)
LocationField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const SummaryField: SimpleEntryWriteComponent = (props) => (
	<div className='entry-write-descriptor'>
		<textarea id='summary' className='summary' value={props.entry.summary || ''}
			placeholder={'e.g.\n- 10:00 Meeting.\n- 18:00 Family call.'}
			onChange={e => props.setValue('summary', e.target.value) } />
		<label htmlFor='summary'><FontAwesomeIcon icon='list-alt' /></label>
	</div>)
SummaryField.propTypes = SIMPLE_ENTRY_WRITE_PROPS

const ModificationConflictMessage = (props: { entry: Entry }): JSX.Element | null => {
	const { dispatch } = useContext(EntryContext)
	if (props.entry.saveStatus === 'conflicted') {
		const overwrite = () => dispatch({ type: 'ENTRY_CONFLICT_OVERWRITE', id: props.entry.id })
		const discard = () => dispatch({ type: 'ENTRY_CONFLICT_DISCARD', id: props.entry.id })
		return <div className='tip error'>
			<FontAwesomeIcon id='modification-conflict-warning' icon='exclamation-triangle' />
			Warning: this entry may have been modified on another device. <br />
			You can <span className='clickable' onClick={overwrite}>overwrite</span> those changes with the ones you have here,
			or <span className='clickable' onClick={discard}>discard</span> your local modifications.
		</div>
	}
	return null
}
ModificationConflictMessage.propTypes = {
	entry: SIMPLE_ENTRY_WRITE_PROPS.entry
}

export default FormFields
