import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import SettingsIcon from './SettingsIcon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { SettingsContext } from '../contexts/SettingsContext'
import { faToggleOn, faToggleOff, faQuestionCircle, faPowerOff, faPlug,
	faSyncAlt, faRocket, faHand, faPlay } from '@fortawesome/free-solid-svg-icons'
import { SettingsAction, SettingsInstanceId } from '../helpers/action/SettingsAction.d'
import { actionInProgress } from '../helpers/action/action'
import { STATUS_ICON_LOADING } from '../contexts/SettingsContext.d'
library.add(faToggleOn, faToggleOff, faQuestionCircle, faPowerOff, faPlug, faSyncAlt, faRocket, faHand, faPlay)

const instanceTitle = (shorthand: SettingsInstanceId) => {
	switch (shorthand) {
	case 'gocd_production':
		return 'GoCD production'
	case 'gocd_testing':
		return 'GoCD testing'
	case 'diary_production':
		return 'Production VM'
	case 'diary_production_db':
		return 'Production database'
	case 'diary_flash':
		return 'Flash VM'
	case 'diary_flash_db':
		return 'Flash database'
	default:
		return 'Unknown instance'
	}
}

const InstanceControl = (props: { instanceId: SettingsInstanceId }): JSX.Element => {
	const { state } = useContext(SettingsContext)
	const syncAction: SettingsAction = { type: 'CHECK_INSTANCE_STATE', instanceId: props.instanceId }
	const turnOnAction: SettingsAction = { type: 'TURN_INSTANCE_ON', instanceId: props.instanceId }
	const turnOffAction: SettingsAction = { type: 'TURN_INSTANCE_OFF', instanceId: props.instanceId }
	const isEnabled = actionInProgress(syncAction, state)
		|| actionInProgress(turnOnAction, state)
		|| actionInProgress(turnOffAction, state)
		? STATUS_ICON_LOADING : state.instances?.[props.instanceId]
	return (
		<div className='thumbnail thumbnail-static'>
			<h4>{instanceTitle(props.instanceId)} status</h4>
			<SettingsIcon className='status' icon={isEnabled} />
			<SettingsIcon icon='sync-alt' action={syncAction} />
			<div className='separator' />
			<SettingsIcon icon='plug' action={turnOnAction} />
			<SettingsIcon icon='power-off' action={turnOffAction} />
		</div>
	)
}
InstanceControl.propTypes = {
	instanceId: PropTypes.string.isRequired
}

export default InstanceControl
