import React, { useState, useContext } from 'react'
import ErrorMessage from './ErrorMessage'
import PropTypes, { InferProps, ReactComponentLike } from 'prop-types'
import Loading from './Loading'
import { BasicContextProvided, BasicContextState } from '../helpers/context.d'
import { PrimaryAction } from '../helpers/action/Action.d'
import { actionPending, actionQueryCompleted, conditionalDispatch } from '../helpers/action/action'

type ArbitraryProps = Record<string, unknown>
export type IsDataPresent<S extends BasicContextState = BasicContextState>
	= (data: S, props: ArbitraryProps) => boolean
export type ActionToDispatch<
	S extends BasicContextState = BasicContextState,
	A extends PrimaryAction = PrimaryAction
> = (data: S, props: ArbitraryProps) => A | undefined

type LoadingMessage<
    S extends BasicContextState = BasicContextState,
	A extends PrimaryAction = PrimaryAction>
	= (data: S, props: A) => string | undefined

export type RetrieverConfig<
	S extends BasicContextState = BasicContextState,
	A extends PrimaryAction = PrimaryAction> = (dataType: ReactComponentLike) => {
	isDataPresent: IsDataPresent<S>
	actionToDispatch: ActionToDispatch<S, A>
    loadingMessage?: LoadingMessage<S, A>
    /** If true, the query will be rerun on page load.
     *   Note: requires the action's Reducer to set state.actionStatus.
     */
    refresh?: boolean
} | undefined

/**
 * Retrievers are a way to simplify verifying and requesting API data.
 * Components which require such data should be added to a relevant Retriever
 *  (defined in the file for the appropriate Reducer), and should indicate how
 *  to check if the data is present and what action to dispatch to request it.
 * Such components should then always be directly wrapped with a <type>Retriever
 *  which will perform necessary checks and only render its child(ren) once
 *  the data is available.
 */
const Retriever = (props: InferProps<typeof retrieverPropTypes>): JSX.Element => {
	// Initialise data structures
	const { state, dispatch } = useContext(props.context as React.Context<BasicContextProvided>)
	if (!state) {
		throw new Error('No state found in context object')
	}
	const [paused, setPaused] = useState<boolean>(false)

	// Work out what broad type of data retrieval we're dealing with.
	if (!props.dataType && Array.isArray(props.children)) {
		throw new Error('Required a React element as Retriever child')
	}
	const dataType = props.dataType ? props.dataType : props.children.type
	const config = (props.config as RetrieverConfig)(dataType)
	if (!(config && 'isDataPresent' in config && 'actionToDispatch' in config)) {
		console.error(`Unknown data type '${
			typeof dataType === 'function' ? dataType.name : dataType
		}' for data retriever`)
		return <></>
	}

	// Determine all details of the relevant request.
	const allRelevantProps = { ...(props.children.props as Record<string | number, unknown>), ...props }
	const action = config.actionToDispatch(state, allRelevantProps)
	const identifier = JSON.stringify(action)

	// If we shouldn't now, or can't ever, output the element, move on.
	if (props.inactive || !action) {
		return <></>

	// If the request failed, indicate that.
	} else if (state.failed && identifier in state.failed) {
		const message = state.failed[identifier] === 404 && props.messageOn404
			? props.messageOn404 : state.failed[identifier]
		return <ErrorMessage action={action} status={message} dispatch={dispatch}/>

	// If we don't have the data but aren't waiting for it, trigger an action.
	} else if (!paused && !actionPending(action, state)
		&& (!config.refresh && !config.isDataPresent(state, allRelevantProps)
			|| config.refresh && !actionQueryCompleted(action, state))) {
		setPaused(true)
		setTimeout(() => {
			conditionalDispatch(action, state, dispatch)
			setPaused(false)
		})
		return <></>	// Output nothing at all.
	}

	const loadingMessage = typeof config.loadingMessage === 'function'
		? config.loadingMessage(state, action)
		: config.loadingMessage
	const loading: JSX.Element = <Loading message={loadingMessage} />

	// If we have all the data we need, we can safely load the children.
	if (config.isDataPresent(state, allRelevantProps))
		return <>
			{props.children}
			{ config.refresh && !actionQueryCompleted(action, state) && loading }
		</>
	return loading
}
const retrieverPropTypes = {
	children: PropTypes.element.isRequired,
	context: PropTypes.object.isRequired,
	config: PropTypes.func.isRequired,
	messageOn404: PropTypes.string,
	inactive: PropTypes.bool,
	dataType: PropTypes.string
}
Retriever.propTypes = retrieverPropTypes

export default Retriever
