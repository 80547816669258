import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Statistic from '../common/Statistic'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEgg, faTrophy, faPeopleArrows, faHistory, faVenus, faMars, faTransgender,
	faCalendarAlt, faStar, faFireAlt, faHourglassStart, faComment, faHourglassEnd, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { Person } from '../contexts/PersonContext.d'
import { AppContext } from '../contexts/AppContext'
import { formatDate, formatDayInterval } from '../helpers/format'
import { Link } from 'react-router-dom'
library.add(faEgg, faTrophy, faPeopleArrows, faHistory, faVenus, faMars, faTransgender,
	faUserFriends, faCalendarAlt, faStar, faFireAlt, faHourglassStart, faHourglassEnd, faComment)

const PersonMetadata = (props: { person: Person }): JSX.Element => {
	const { isUserType } = useContext(AppContext)
	return <>
		<PersonMetadataSimple person={props.person} />
		{isUserType('power') && <PersonMetadataExtended person={props.person} extended={true} />}
	</>
}

const PersonMetadataSimple = (props: { person: Person }): JSX.Element =>
	<div className='person-metadata-summary'>
        #{props.person.id}: {props.person.count}× across <Link to={`/entries/with/person/${props.person.id}`}>
			{props.person.entries} {props.person.entries === 1 ? 'entry' : 'entries'}
		</Link>{
			props.person.first === props.person.last
				? <> on {formatDate(props.person.first, 'short')}.</>
				: <> {}
					from <Link to={`/write?date=${formatDate(props.person.first)}`}>{formatDate(props.person.first, 'short')}</Link> {}
					to <Link to={`/write?date=${formatDate(props.person.last)}`}>{formatDate(props.person.last, 'short')}</Link> {}
					({formatDayInterval(props.person.duration)}). {}
					{ props.person.entries != 1 && <Link to={`/people/by/person/${props.person.id}`}>See links.</Link>  }
				</>}
	</div>

const PersonMetadataExtended = (props: { person: Person, extended?: boolean }): JSX.Element =>
	<div className='person-metadata-row'>
		<Statistic icon='people-arrows' value={props.person.sociability}
			link={'/people/by/person/' + props.person.id}
			name='Links: the number of people this person knows' />
		{ props.person.gender_conf && props.person.gender_conf >= 0.55 && <Statistic
			icon={props.person.gender_guess == 'f' ? 'venus' : props.person.gender_guess == 'm' ? 'mars' : 'transgender'}
			link={'/people/with/gender_guess/' + props.person.gender_guess} units='percent' value={props.person.gender_conf}
			name={'Gender: confidence rating that this person is ' +
			(props.person.gender_guess == 'f' ? 'female' : props.person.gender_guess == 'm' ? 'male' : 'non-binary')} />}
		<Statistic icon='history' units='days_ago' value={props.person.last}
			link="/entries?date=:value"
			name="Age: how long since this person was last mentioned" />
		{props.extended && <>
			<Statistic icon='user-friends' value={props.person.group}
				link="/people/with/group/exactly/:value"
				name='Group: which rank of social group this person belongs to' />
			<Statistic icon='star' value={props.person.sentiment}
				link='/people/with/sentiment/:value'
				name='Rating: average rating of the days this person appears' />
			<Statistic icon='fire-alt' units='frequency' value={props.person.intensity}
				link='/people/with/intensity/:value'
				name='Intensity: times mentioned per episode' />
			<Statistic icon='hourglass-start' units='interval' value={props.person.sparsity}
				link='/people/with/sparsity/:value'
				name='Sparsity: the average number of days between mentions' />
		</>}
	</div>

const personMetadataPropTypes = {
	person: PropTypes.shape({
		rank: PropTypes.number,
		sociability: PropTypes.number,
		id: PropTypes.number,
		count: PropTypes.number,
		first: PropTypes.string,
		gender_guess: PropTypes.oneOf(['f', 'm', 'n']),
		gender_conf: PropTypes.number,
		last: PropTypes.string,
		entries: PropTypes.number,
		group: PropTypes.number,
		sentiment: PropTypes.number,
		intensity: PropTypes.number,
		sparsity: PropTypes.number,
		age: PropTypes.number,
		duration: PropTypes.number
	}).isRequired,
	extended: PropTypes.bool
}
PersonMetadataExtended.propTypes = personMetadataPropTypes

export default PersonMetadata
