import { AppContextState } from '../contexts/AppContext.d'
import { INSTANCE_CONTROL_STARTING, INSTANCE_CONTROL_STOPPING, STATUS_ICON_WORKING, STATUS_PREPARE_PENDING,
	InstanceStatusCode, SettingsContextState
} from '../contexts/SettingsContext.d'
import { conditionalDelayedDispatch, conditionalDispatch } from '../helpers/action/action'
import { ApiResponseAction, Dispatch, PrimaryAction } from '../helpers/action/Action.d'
import { CheckInstanceAction, SettingsAction, SettingsInstanceId, SETTINGS_SYNC_APIS } from '../helpers/action/SettingsAction.d'

// How long to wait between automatic status checks.
const PREPARE_INTERVAL_SECONDS = 20
const INSTANCE_INTERVAL_SECONDS = 5

export const checkInstanceAction = (instance: SettingsInstanceId): CheckInstanceAction => ({
	type: instance === 'prepare' ? 'CHECK_PREPARE_STATUS' : 'CHECK_INSTANCE_STATE',
	instanceId: instance
})

export const isInstanceStatusChanging = (
	instance: SettingsInstanceId | CheckInstanceAction | ApiResponseAction<CheckInstanceAction>,
	state: SettingsContextState): boolean => {
	const status = instanceStatus(instance, state)
	const fluxStatuses: InstanceStatusCode[] = [
		STATUS_ICON_WORKING, STATUS_PREPARE_PENDING,
		INSTANCE_CONTROL_STARTING, INSTANCE_CONTROL_STOPPING]
	return fluxStatuses.includes(status)
}

export const instanceStatus = (
	instance: SettingsInstanceId | CheckInstanceAction | ApiResponseAction<CheckInstanceAction>,
	state: SettingsContextState
): InstanceStatusCode =>
	state.instances?.[
		typeof instance === 'string'
			? instance
			: 'sourceAction' in instance
				? instance.sourceAction.instanceId
				: instance.instanceId ]

const triggerCheck = (instance: SettingsInstanceId,
	state: SettingsContextState,
	dispatch: Dispatch<SettingsAction>,
	initialCheck = true): void => {
	if (state.windowFocused === false) {
		return
	}
	const status = instanceStatus(instance, state)
	if (status === undefined && initialCheck) {
		conditionalDispatch(checkInstanceAction(instance), state, dispatch)
	} else if (isInstanceStatusChanging(instance, state)) {
		conditionalDelayedDispatch(checkInstanceAction(instance), state, dispatch,
			instance === 'prepare' ? PREPARE_INTERVAL_SECONDS : INSTANCE_INTERVAL_SECONDS)
	}
}

// Run on loading the Admin page.
export const triggerInitialInstanceChecks = (state: SettingsContextState, dispatch: Dispatch<SettingsAction>, admin = true): void => {
	triggerCheck('prepare', state, dispatch)
	if (!admin) { return }
	triggerCheck('diary_production', state, dispatch, true)
	triggerCheck('diary_flash_db', state, dispatch, true)
}

// Run at the AppContext level.
export const saveUserFields = (state: AppContextState, dispatch: Dispatch<PrimaryAction>): void => {
	if (!state.user?.row || !state.user.updated) {
		return
	}
	for (const field in state.user.updated) {
		saveUserField(field, state, dispatch)
	}
}

const saveUserField = (field: string, state: AppContextState, dispatch: Dispatch<PrimaryAction>): void => {
	if (state?.user?.updated?.[field] === undefined) { return }
	for (const api of SETTINGS_SYNC_APIS) {
		if (field.startsWith(api)) { return }
	}
	conditionalDispatch({ type: 'SAVE_USER_FIELD', field, value: state.user.updated[field] }, state, dispatch)
}

export const specialSyncActions = (
	appState: AppContextState,
	appDispatch: Dispatch<PrimaryAction>): void => {
	for (const api of ['fitbit', 'rescuetime'] as const) {
		for (const fieldBase of ['start_date', 'user_id', 'api_key']) {
			const field = `${api}_${fieldBase}`
			const baseValue = appState.user?.row?.[field]
			const modifiedValue = appState.user?.updated?.[field] as string
			if (modifiedValue !== undefined && baseValue !== modifiedValue) {
				const saveAction: PrimaryAction = { type: 'SAVE_USER_FIELD', field, value: modifiedValue }
				conditionalDelayedDispatch(saveAction, appState, appDispatch, 1)
			}
		}
	}
}
