import React, { Context, createContext, useContext, useEffect, useReducer } from 'react'
import { ChildrenOnlyProps, ContextConfig } from '../helpers/context.d'
import { defaultContextConfig, initialiseState } from '../helpers/context'
import { InfiniteScrollContextProvided } from '../common/InfiniteScroll.d'
import { InfiniteScrollConfig } from '../helpers/context.d'
import { generateDispatcher } from '../helpers/dispatch'
import { SuperDispatch } from '../helpers/dispatch.d'
import { AppContext } from './AppContext'
import PropTypes from 'prop-types'
import { AnyAction, Dispatch } from '../helpers/action/Action.d'
import { VoiceContextProvided, VoiceContextState } from './VoiceContext.d'
import { VoiceDispatcher, VoiceReducer } from '../reducers/VoiceReducer'
import { VoiceAction } from '../helpers/action/VoiceAction'
import { conditionalDispatch } from '../helpers/action/action'
import { isUsingField } from './app/auth'

export const TRANSCRIPT_POLL_DELAY = 3000

const defaultContextProvided: VoiceContextProvided = {
	state: {},
	dispatch: () => { throw new Error('Dummy VoiceContext used') }
}

export const VoiceContext = createContext<VoiceContextProvided>(defaultContextProvided)

export const contextConfig: ContextConfig<VoiceContextState> & InfiniteScrollConfig = {
	...defaultContextConfig,
	context: VoiceContext as unknown as Context<InfiniteScrollContextProvided>,
	localStorageLocation: 'voice',
	contextDataLocation: [],
	freshState: { waiting: 'waiting' },
	actionTypes: {
		loadDetails: [],
		loadIds: []
	}
}

const contextLevelVoiceActions = (state: VoiceContextState, dispatch: Dispatch<VoiceAction>) => {
	if (state.deleting) {
		conditionalDispatch({ type: 'VOICE_DELETE' }, state, dispatch)

	} else if (state.waiting === 'waiting') {
		conditionalDispatch({ type: 'VOICE_RETRIEVE_TRANSCRIPTION' }, state, dispatch)

	} else if (!state.waiting && state.voiceData) {
		conditionalDispatch({ type: 'VOICE_TRANSCRIBE', data: state.voiceData }, state, dispatch)
	}
}

const VoiceContextProvider = (props: ChildrenOnlyProps): JSX.Element => {
	const appContext = useContext(AppContext)

	// Initialise.
	const [state, reducerDispatch] = useReducer<typeof VoiceReducer, VoiceContextState>(VoiceReducer, defaultContextProvided.state,
		initialiseState(contextConfig, appContext.appState))

	const dispatch = generateDispatcher(reducerDispatch as Dispatch<AnyAction>, VoiceDispatcher as SuperDispatch, appContext)

	if (isUsingField('voice', appContext.appState)) {
		contextLevelVoiceActions(state, dispatch)
	}

	// Save any updates in local storage.
	useEffect(() => localStorage.setItem('voice', JSON.stringify(state)), [state])

	return (
		<VoiceContext.Provider value={{ state, dispatch }}>
			{props.children}
		</VoiceContext.Provider>
	)
}
VoiceContextProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export default VoiceContextProvider
