import { Reducer } from 'react'
import { treatLocalAction, treatApiAction } from '../helpers/context'
import { AnyAction, ApiResponseAction, PrimaryAction } from '../helpers/action/Action.d'
import { SuperDispatch } from '../helpers/dispatch.d'
import { VoiceContextState } from '../contexts/VoiceContext.d'
import { contextConfig, TRANSCRIPT_POLL_DELAY } from '../contexts/VoiceContext'
import { VoiceAction } from '../helpers/action/VoiceAction'

export const VoiceReducer: Reducer<VoiceContextState, AnyAction> = (state, action) =>
	'apiResponse' in action
		? VoiceReducerApiResponse(state, action)
		: VoiceReducerLocal(state, action)

// This function governs actions which update local state.
export const VoiceReducerLocal: Reducer<VoiceContextState, PrimaryAction> = (state, action) => {
	switch (action.type) {

	/** Initiate a save request */
	case 'VOICE_CACHE':
		return {
			...state,
			failed: {},
			voiceData: action.recording,
			voiceDuration: action.duration,
			waiting: undefined,
			voiceTranscript: undefined
		}

	case 'VOICE_DELAY_RETRIEVE_TRANSCRIPTION':
		return { ...state, waiting: 'waiting' }

	case 'VOICE_MARK_DELETE':
		return { ...state, deleting: true, voiceData: undefined, voiceDuration: undefined, voiceTranscript: undefined, waiting: undefined }

	default:
		return treatLocalAction(state, action, contextConfig)
	}
}

const VoiceReducerApiResponse: Reducer<VoiceContextState, ApiResponseAction> = (state, action) => {

	switch (action.type) {

	/** Receive details of a successful save request */
	case 'VOICE_TRANSCRIBE':
		return {
			...state,
			voiceTranscript: undefined,
			waiting: 'waiting'
		}

	case 'VOICE_RETRIEVE_TRANSCRIPTION':
		if (action.apiResponse?.data !== undefined) {
			return {
				...state,
				voiceData: undefined,
				voiceTranscript: action.apiResponse.data as string,
				waiting: undefined
			}
		} else {
			return {
				...state,
				waiting: setTimeout(
					() => action.redispatch({ type: 'VOICE_DELAY_RETRIEVE_TRANSCRIPTION' }),
					TRANSCRIPT_POLL_DELAY)
			}
		}

	case 'VOICE_DELETE':
		return { ...state, deleting: false }

	default:
		return treatLocalAction(
			state,
			action,
			contextConfig)
	}
}

// This function governs API-calling actions. They may later end up above.
export const VoiceDispatcher: SuperDispatch<VoiceAction> = (action, dispatch, dispatchToApi) => {

	switch (action.type) {
	case 'VOICE_TRANSCRIBE':
		// TODO compress with gzip for speed
		return dispatchToApi('/general/voice', { method: 'POST', body: action.data, headers: { 'Content-Type': 'audio/webm' } })

	case 'VOICE_RETRIEVE_TRANSCRIPTION':
		return dispatchToApi('/general/voice')

	case 'VOICE_DELETE':
		return dispatchToApi('/general/voice', { method: 'DELETE' })

	default:
		return treatApiAction(action, dispatch, dispatchToApi, contextConfig)
	}
}
