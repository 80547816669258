import React, { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { AppContextState } from '../contexts/AppContext.d'
import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react'
import { Dispatch, PrimaryAction } from '../helpers/action/Action.d'
import { conditionalDispatch } from '../helpers/action/action'
import config from '../common/auth_config.json'
import UnverifiedEmail from './UnverifiedEmail'
import jsonwebtoken from 'jsonwebtoken'
import Loading from '../common/Loading'
import Splash from './Splash'

export const authenticationFailed = (auth0Loading: boolean, auth0IsAuthenticated: boolean, appState: AppContextState): boolean =>
	(!auth0Loading && !auth0IsAuthenticated && !appState.user?.IS_SPECIAL_USER) || !!appState.user?.loginProblemDetected

/** Check whether login-related content can be skipped */
export const isLoggedIn = (appState: AppContextState): boolean =>
	appState.user?.status === 'ready'

export const specialLoginActions = (
	appState: AppContextState,
	auth0: Auth0ContextInterface,
	appDispatch: Dispatch<PrimaryAction>): void => {

	if (appState.user?.status === 'Logging out...' && auth0.isAuthenticated) {
		void auth0.logout({
			logoutParams: { returnTo: window.location.origin }
		})

	} else if (appState.user?.status === 'Logging out...' && !auth0.isLoading && !auth0.isAuthenticated) {
		// Reset the status once logout is complete.
		appDispatch({ type: 'UPDATE_LOGIN_STATUS', status: undefined })

	} else if (!auth0.isLoading && !appState.user?.row && (auth0.isAuthenticated || appState.user?.IS_SPECIAL_USER)) {
		conditionalDispatch({ type: 'LOAD_USER' }, appState, appDispatch)
	}
}

const Login = (): JSX.Element => {
	const { appState, appDispatch } = useContext(AppContext)
	const auth0Context = useAuth0()
	const { isLoading, isAuthenticated, user, getAccessTokenSilently } = auth0Context

	// Load Auth0 user details from the JWT itself.
	if (!isLoading && isAuthenticated && user && !appState.user?.scope &&
		!['Logged out', 'Logging out...'].includes(appState.user?.status || '')) {
		void getAccessTokenSilently({
			authorizationParams: config.credentials.authorizationParams
		}).then(token => {
			if (typeof token !== 'string') {
				return appDispatch({ type: 'LOG_OUT' })
			}
			const content = jsonwebtoken.decode(token, { complete: true })
			if (!content) {
				return appDispatch({ type: 'LOG_OUT' })
			} else if (typeof content === 'string') {
				console.error('JWT cannot be decoded')
				return
			}
			const scope = typeof content.payload === 'string'
				? content.payload
				: typeof content.payload.scope === 'string'
					? content.payload.scope
					: ''
			appDispatch({ type: 'STORE_AUTH', userObj: user, scope })
		})
		return <Loading message='Checking credentials...' />
		// Load app-specific user details from the API, when "Retrieving user data..."
	}

	if (appState.user?.scope && !appState.user?.email_verified) {
		return <UnverifiedEmail />
	}

	if (!appState.user?.status || appState.user.status === 'ready' ||
		(appState.user.status === 'Logging in...' && !isLoading && !appState.user?.IS_SPECIAL_USER)) {
		return <Splash />
	}

	return <Loading message={appState.user.status} />
}

export default Login
