import { InfiniteScrollContextState } from '../../common/InfiniteScroll.d'
import { ApiActionStatus, BasicContextState } from '../context.d'
import { ActionKey, AnyAction, ConditionalDispatchOptions, Dispatch, PrimaryAction, SetInProgressAction } from './Action.d'

export const LOAD_IDS_ACTION_TYPES_CONST = ['QUERY_ENTRIES', 'QUERY_PEOPLE'] as const
export const LOAD_IDS_ACTION_TYPES = LOAD_IDS_ACTION_TYPES_CONST as readonly string[]
export type LOAD_IDS_ACTION_TYPES_TYPE = typeof LOAD_IDS_ACTION_TYPES_CONST[number]

export const LOAD_DETAILS_ACTION_TYPES_CONST = ['LOAD_ENTRIES_BY_IDS',
	'LOAD_ENTRIES_BY_ORDERS', 'LOAD_MORE_PEOPLE', 'GET_PERSON'] as const
export const LOAD_DETAILS_ACTION_TYPES = LOAD_DETAILS_ACTION_TYPES_CONST as readonly string[]
export type LOAD_DETAILS_ACTION_TYPES_TYPE = typeof LOAD_DETAILS_ACTION_TYPES_CONST[number]

/** Thrown within EntryReducer timer functions when constant variables change their values - i.e. never */
export const actionTypeChangedException = (action: AnyAction): Error =>
	new Error('Action of type ' + action.type + ' did not attempt an API call')

/** Whether or not a previous API call failure should prevent rerunning this action */
export const actionFailed = (action: ActionKey, state: BasicContextState): boolean =>
	!!state.failed && actionKey(action) in state.failed

/** Whether or not all results have been returned for the given query */
export const actionQueryCompleted = (action: ActionKey, state: InfiniteScrollContextState): boolean =>
	state.actionStatus?.[actionKey(action)] === 'complete' || actionFailed(action, state)

/** The current status of a given action: waiting for timer, or API, or neither */
export const actionStatus = (action: ActionKey, state: InfiniteScrollContextState): ApiActionStatus =>
	state.inProgress?.[actionKey(action)]

/** Whether the given action is currently waiting for the API */
export const actionInProgress = (action: ActionKey, state: InfiniteScrollContextState): boolean =>
	actionStatus(action, state) === 'requestSent'

/** Whether the given action is currently waiting for the API or a timer */
export const actionPending = (action: ActionKey, state: InfiniteScrollContextState): boolean =>
	!!actionStatus(action, state)

export const actionKey = (action: ActionKey): string =>
	typeof action === 'string' ? action : JSON.stringify(action)

export const setInProgress = <A extends PrimaryAction = PrimaryAction>(
	action: ActionKey, status: ApiActionStatus, dispatch: Dispatch<A | SetInProgressAction>
): void => dispatch({ type: 'SET_IN_PROGRESS', key: actionKey(action), status })

export const cancelInProgress = <A extends PrimaryAction = PrimaryAction>(
	action: ActionKey, state: BasicContextState, dispatch: Dispatch<A | SetInProgressAction>): void => {
	const status = actionStatus(action, state)
	if (typeof status === 'number') {
		clearTimeout(status)
	}
	setInProgress(action, undefined, dispatch)
}

/** Dispatches an API request action if one is not currently in progress */
export const conditionalDispatch = <A extends PrimaryAction = PrimaryAction>(
	action: A | SetInProgressAction,
	state: BasicContextState,
	dispatch: Dispatch<A | SetInProgressAction>,
	{
		key = JSON.stringify(action),
		retryFailed = false
	}: ConditionalDispatchOptions = {}): void => {
	if (!actionPending(key, state) && (retryFailed || !actionQueryCompleted(key, state))) {
		setInProgress(key, 'requestSent', dispatch)
		dispatch(action)
	}
}

/** Creates a timer for a delayed action unless one is already counting down */
export const conditionalDelayedDispatch = <A extends PrimaryAction = PrimaryAction>(
	action: A,
	state: BasicContextState,
	dispatch: Dispatch<A | SetInProgressAction>,
	delaySeconds: number,
	key: string = JSON.stringify(action)): void => {
	if (!actionPending(key, state) && !actionFailed(key, state)) {
		const timerId = setTimeout(() => {
			setInProgress(key, 'requestSent', dispatch)
			dispatch(action)
		}, delaySeconds * 1000)
		setInProgress(key, Number(timerId), dispatch)
	}
}
