import React, { Reducer } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import Retriever, { RetrieverConfig } from '../common/Retriever'
import PersonLink from '../Person/PersonLink'
import PersonContent from '../Person/PersonContent'
import PersonThumbnail from '../Person/PersonThumbnail'
import { treatLocalAction, treatApiAction } from '../helpers/context'
import { PersonContext, contextConfig } from '../contexts/PersonContext'
import { PersonContextState } from '../contexts/PersonContext.d'
import { AnyAction, ApiResponseAction, PrimaryAction } from '../helpers/action/Action.d'
import { PersonAction, SetShownSpanGraphAction } from '../helpers/action/PersonAction.d'
import { SuperDispatch } from '../helpers/dispatch.d'
import { formatDate } from '../helpers/format'
import Graph from '../common/Graph'

export const PersonReducer: Reducer<PersonContextState, AnyAction> = (state, action) => {
	return 'apiResponse' in action
		? PersonReducerApiResponse(state, action)
		: PersonReducerLocal(state, action)
}

export const PersonReducerApiResponse: Reducer<PersonContextState, ApiResponseAction> = (state, action) =>
	treatLocalAction(state, action, contextConfig)

const updateSpanGraphs: Reducer<PersonContextState, SetShownSpanGraphAction> = (state, action) => {
	const closingShownGraph = state.spanGraphShown
            && state.spanGraphShown.start === action.start
            && state.spanGraphShown.end === action.end
	if (state.spanGraphShown) {
		delete state.graphs?.[JSON.stringify({ type: 'GRAPH_PEOPLE_CLOUD_BETWEEN_DATES', ...state.spanGraphShown } as PrimaryAction)]
		delete state.graphs?.[JSON.stringify({ type: 'GRAPH_PEOPLE_LINE_BETWEEN_DATES', ...state.spanGraphShown } as PrimaryAction)]
	}
	if (closingShownGraph) {
		delete state.spanGraphShown
		return { ...state }
	}
	return {
		...state,
		spanGraphShown: {
			start: action.start,
			end: action.end
		}
	}
}

// This function governs actions which update local state.
export const PersonReducerLocal: Reducer<PersonContextState, PrimaryAction> = (state, action) => {
	switch (action.type) {
	case 'SELECT_PERSON':
		return { ...state, selectedPerson: Number(action.personId) }

	case 'SET_SHOWN_SPAN_GRAPH':
		return updateSpanGraphs(state, action)

	default:
		return treatLocalAction(state, action, contextConfig)
	}
}

// This function governs API-calling actions. They may later end up above.
export const PersonDispatcher: SuperDispatch<PersonAction> = (action, dispatch, dispatchToApi) => {
	switch (action.type) {

	case 'GRAPH_PEOPLE_OVER_TIME':
		return dispatchToApi(`graph/person?selection=${action.people.join(',')}&format=svg&ratio=1.5`)

	case 'GRAPH_PEOPLE_CLOUD_BETWEEN_DATES':
		return dispatchToApi(`graph/cloud/between/${
			formatDate(action.start * 1000)}/${formatDate(action.end ? action.end * 1000 : new Date())
		}?format=png&width=7&height=7&resolution=150`)

	case 'GRAPH_PEOPLE_LINE_BETWEEN_DATES':
		return dispatchToApi(`graph/people/between/${
			formatDate(action.start * 1000)}/${formatDate(action.end ? action.end * 1000 : new Date())
		}?format=png&width=7&height=4&resolution=150`)

	case 'GRAPH_PEOPLE_FOR_PERSON':
		return dispatchToApi(`graph/cloud?person=${action.person}&format=png&width=8&height=6&resolution=150`)

	default:
		return treatApiAction(action, dispatch, dispatchToApi, contextConfig)
	}
}

// This function governs loading of data: see common/Retriever.js for details.
const PersonRetrieverConfig: RetrieverConfig<PersonContextState, PrimaryAction> = (dataType) => {
	switch (dataType) {
	case PersonContent:
	case PersonLink:
	case PersonThumbnail:
		return {
			isDataPresent: (state, props: { personId?: number | string }) =>
				!!state.people && !!props.personId && props.personId in state.people,
			actionToDispatch: (_state, props) => ({ type: 'GET_PERSON', id: Number(props.personId) })
		}
	case Graph:
		return {
			isDataPresent: (state, props: { action?: PrimaryAction }) =>
				!!state.graphs && !!props.action && JSON.stringify(props.action) in state.graphs,
			actionToDispatch: (_state, props: { action?: PrimaryAction }) => props.action,
			loadingMessage: (_, action) => {
				switch (action.type) {
				case 'GRAPH_PEOPLE_CLOUD_BETWEEN_DATES':
					return 'Drawing wordcloud...'
				default:
					return 'Drawing graph...'
				}
			}
		}
	default:
		return undefined
	}
}

export const PersonRetriever = (props: InferProps<typeof personRetrieverPropTypes>): JSX.Element =>
	<Retriever context={PersonContext} messageOn404="Nobody could be found" config={PersonRetrieverConfig} inactive={props.inactive}>
		{props.children}
	</Retriever>

const personRetrieverPropTypes = {
	children: PropTypes.element.isRequired,
	inactive: PropTypes.bool
}
PersonRetriever.propTypes = personRetrieverPropTypes
