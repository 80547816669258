import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { GraphContextProvided } from '../helpers/context.d'
import { PrimaryAction } from '../helpers/action/Action.d'
import { sanitize } from 'dompurify'

const Graph = (props: { action: PrimaryAction, context: React.Context<GraphContextProvided>}): JSX.Element => {
	const { state, dispatch } = useContext(props.context)
	const key = JSON.stringify(props.action)
	if (!props.action || !state.graphs || !(key in state.graphs)) {
		return <></>
	}
	const source = state.graphs[key]
	const onClick = () => dispatch({ type: 'CLEAR_GRAPH', graph: key })

	if (!source) {
		return <></>
	} else if (source.startsWith('<')) {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		return <div onClick={onClick} className='graph' dangerouslySetInnerHTML={{ __html: sanitize(source) }} />
	} else {
		return <img onClick={onClick} className='graph' src={`data:image/png;base64,${source}`} />
	}
}

const graphPropTypes = {
	context: PropTypes.object.isRequired,
	action: PropTypes.object.isRequired
}
Graph.propTypes = graphPropTypes

export default Graph
