import { getBasePageName, isCurrentPage, getShortcut } from '../../helpers/path'
import { Dispatch, PrimaryAction } from '../../helpers/action/Action.d'
import { AppContextState, LoginScope, UserStateChecker } from '../AppContext.d'
import { History } from 'history'

type CatchShortcuts = (shortcutsActive: boolean, history: { push: History['push']},
	path: string, appState: AppContextState, hasLoginScope: UserStateChecker<LoginScope>)
        => () => () => void
type GenerateShortcutsListener = (...parameters: Parameters<CatchShortcuts>) =>
        (event: { key: string, ctrlKey?: boolean, metaKey?: boolean }) => void;

export const generateShortcutsListener: GenerateShortcutsListener = (shortcutsActive, history, path, appState, hasLoginScope) =>
	(keydown) => {
		if (!shortcutsActive) { return }
		if (keydown.ctrlKey || keydown.metaKey) { return }
		const shortcut = getShortcut(keydown.key, path)
		if (!shortcut || // skip if: key isn't a shortcut,
			// strict shortcut hasn't yet been visited,
			(shortcut.strict && !(shortcut.target in (appState.paths || {}))) ||
			// or shortcut requires unmet scope
			(shortcut.scope && !hasLoginScope(shortcut.scope))) {
			return
		}
		const targetPage: string = getBasePageName(shortcut.target, true)
		const target = !isCurrentPage(targetPage, path, { shortcut: true }) &&
			targetPage in (appState.pages || {})
			? appState.pages?.[targetPage]
			: (shortcut.target in (appState.paths || {})
				? appState.paths?.[shortcut.target] : shortcut.target)
		if (path === target || !target) { return }
		history.push(target)
	}

/** Generate a function to respond to shortcuts and change pages accordingly */
export const catchShortcuts: CatchShortcuts = (shortcutsActive, history, path, appState, hasLoginScope) => () => {
	const keydown = generateShortcutsListener(shortcutsActive, history, path, appState, hasLoginScope)
	window.addEventListener('keydown', keydown)
	return () => {
		window.removeEventListener('keydown', keydown)
	}
}

/** Note down in the app cache when we change a page */
export const cacheCurrentPage = (path: string, appState: AppContextState,
	appDispatch: Dispatch<PrimaryAction>): void => {
	const page: string = getBasePageName(path, true)
	if (path !== '/' && appState.pages?.[page] !== path) {
		appDispatch({ type: 'CHANGE_URL', path })
	}
}
