import React, { useContext } from 'react'
import NavBarButton from './NavBarButton'
import SectionNavBarSettings from './SectionNavBarSettings'
import { useAuth0 } from '@auth0/auth0-react'
import { AppContext } from '../contexts/AppContext'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faUserCircle, faUserSlash, faPlaneCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { authenticationFailed } from '../site/Login'
library.add(faSignOutAlt, faUserCircle, faUserSlash, faPlaneCircleExclamation)

export const UserNavBarToggleButton = (): JSX.Element => {
	const { isLoading, isAuthenticated } = useAuth0()
	const { appState } = useContext(AppContext)
	const className = isLoading ? '' : 'disabled'
	return <NavBarButton togglesMenu='user-nav-bar' className={className}
		alt={appState.user?.picture && 'User'}
		src={appState.user?.picture}
		text="User"
		overlay={isLoading ? 'spinner' :
			authenticationFailed(isLoading, isAuthenticated, appState)
				? 'user-slash' :
				appState.offlineMode ? 'plane-circle-exclamation' : false}
		icon='user-circle' />
}

const UserNavBar = (): JSX.Element => {
	const { appDispatch } = useContext(AppContext)
	return <>
		<NavBarButton text='Log out' icon='sign-out-alt' onClick={() => appDispatch({ type: 'LOG_OUT' })} className='logout-icon'/>
		<SectionNavBarSettings />
	</>
}

export default UserNavBar
