import React, { useContext } from 'react'
import EntryMetadata from './EntryMetadata'
import EntryTitleLine from './EntryTitleLine'
import EntryContentText from './EntryContentText'
import { EntryContext } from '../contexts/EntryContext'
import { useHistory } from 'react-router-dom'
import PropTypes, { InferProps } from 'prop-types'
import ErrorMessage from '../common/ErrorMessage'
import { AppContext } from '../contexts/AppContext'

const EntryThumbnail = (props: InferProps<typeof entryThumbnailPropTypes>): JSX.Element => {
	const { isUserType } = useContext(AppContext)
	const { state } = useContext(EntryContext)
	const history = useHistory()
	const entry = state.write?.[props.entryId] || state.entries?.[props.entryId]
	if (!entry)
		return <ErrorMessage status='Entry not found' />

	const staticOrLink = 'thumbnail-link entry-thumbnail-link'
	return (
		<div
			className={'thumbnail entry-thumbnail ' + staticOrLink}
			onClick={ (event) => {
				// eslint-disable-next-line dot-notation
				if (event.target['classList'].contains('entry-thumbnail-link')) {
					history.push(`/write?entryId=${props.entryId}`)
				}
			}}>
			{ !isUserType('public') &&
				<EntryTitleLine className={staticOrLink} entry={entry} /> }
			{ isUserType('power') && <EntryMetadata className={staticOrLink} entry={entry} /> }
			<EntryContentText showSummary={true} entry={entry} />
		</div>
	)
}

const entryThumbnailPropTypes = {
	entryId: PropTypes.number.isRequired
}
EntryThumbnail.propTypes = entryThumbnailPropTypes

export default EntryThumbnail
