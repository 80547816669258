import React from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { PROP_TYPES_ENTRY } from './Entry.d'
import { formatDate, formatDayOfWeek } from '../helpers/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EntryTitleLine = (props: InferProps<typeof entryTitleLinePropTypes>): JSX.Element => {
	return <h3 className={props.className || undefined}>
		{ isNaN(new Date(props.entry.date).getTime()) ? 'Invalid date'
			: formatDayOfWeek(props.entry.date) + ' ' + formatDate(props.entry.date, 'long') }
		{props.entry.title && <> &bull; <span className='entry-title'>{props.entry.title}</span></>}
		{ props.entry.status === 'deleted' &&
			<span title='This entry has been deleted, and will vanish when processing is next run.'> <FontAwesomeIcon icon='ban' /></span>}
	</h3>
}

const entryTitleLinePropTypes = {
	entry: PROP_TYPES_ENTRY.isRequired,
	className: PropTypes.string
}
EntryTitleLine.propTypes = entryTitleLinePropTypes

export default EntryTitleLine
