import React, { useContext, useEffect } from 'react'
import Prepare from './Prepare'
import InstanceControl from './InstanceControl'
import { cancelInProgress } from '../helpers/action/action'
import { library } from '@fortawesome/fontawesome-svg-core'
import { SettingsContext } from '../contexts/SettingsContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SETTINGS_INSTANCE_IDS } from '../helpers/action/SettingsAction.d'
import { isInstanceStatusChanging, checkInstanceAction } from './controller'
import { buildLogLink, currentCommitLabel, currentCommitLink } from '../helpers/environment'
import { faToggleOn, faToggleOff, faPowerOff, faPlug } from '@fortawesome/free-solid-svg-icons'

library.add(faToggleOn, faToggleOff, faPowerOff, faPlug)

const Admin = (): JSX.Element => {
	const { state, dispatch } = useContext(SettingsContext)

	useEffect(() => {
		/** Permit checks, and cause in-flux statuses to be verified immediately */
		const setWindowFocused = () => {
			for (const instance of SETTINGS_INSTANCE_IDS) {
				if (isInstanceStatusChanging(instance, state)) {
					dispatch({ type: 'RESET_INSTANCE_STATUS', instanceId: instance })
				}
			}
			dispatch({ type: 'SET_WINDOW_FOCUSED', focused: true })
		}
		/** Cancel any existing check timers and prevent more from being created */
		const setWindowNotFocused = () => {
			dispatch({ type: 'SET_WINDOW_FOCUSED', focused: false })
			for (const instance of SETTINGS_INSTANCE_IDS) {
				if (isInstanceStatusChanging(instance, state)) {
					cancelInProgress(checkInstanceAction(instance), state, dispatch)
				}
			}
		}
		window.addEventListener('focus', setWindowFocused)
		window.addEventListener('blur', setWindowNotFocused)
		return () => {
			window.removeEventListener('focus', setWindowFocused)
			window.removeEventListener('blur', setWindowNotFocused)
		}
	})

	return <>
		<h2>Administration</h2>

		<div className='thumbnail-list settings-thumbnail-list admin-thumbnail-list'>

			<Prepare admin={true} />

			<div className='thumbnail thumbnail-static'>
				<div className='tip'>Current app version: <a href={currentCommitLink()}>{currentCommitLabel()}</a>.
                See <a href={buildLogLink()}>deployment log</a>.
				</div>
			</div>

			{ (['diary_production', 'diary_flash_db', 'diary_flash', 'gocd_production'] as const)
				.map((instanceId) =>
					<InstanceControl key={instanceId} instanceId={instanceId} />)
			}

			<div className='thumbnail thumbnail-static'>
				<h3>User administration</h3>
					Use the <a target='_blank' rel='noopener noreferrer'
					href='https://manage.auth0.com/dashboard/eu/diary-processing/users'>
						Auth0 page<FontAwesomeIcon className='external-link' icon='external-link-alt' />
				</a>.
			</div>

		</div>
	</>
}

export default Admin
