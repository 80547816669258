import PropTypes from 'prop-types'
import { Id } from '../helpers/action/Action.d'

export type EntryId = Id
type ENTRY_TYPE_NORMAL = 42
/** @deprecated legacy entries only */
type ENTRY_TYPE_SEJOUR_PLUS = 39
export const ENTRY_TYPE_SPAN = 40 as const
type ENTRY_TYPE_LEGACY = ENTRY_TYPE_SEJOUR_PLUS | 41 | 43 | 44
type EntryType = typeof ENTRY_TYPE_SPAN | ENTRY_TYPE_NORMAL | ENTRY_TYPE_LEGACY

const saveStatusConst = ['saving', 'waiting', 'failed', 'conflicted', 'saved', 'deleting', 'deleted', 'refreshing'] as const
const statusConst = ['new', 'processed', 'modified', 'deleted'] as const

export type EntryStatus = typeof saveStatusConst[number]

export type NotAnEntry = {
	id?: undefined,
	date?: undefined,
	saveStatus?: undefined
	status?: undefined
  timeout?: undefined
}
export type Entry = {
	id: EntryId,
	date: string,
	saveStatus?: EntryStatus
	status?: typeof statusConst[number]
	title?: string,
	entry?: string,
	summary?: string,
	type?: EntryType,
	loc?: string,
	startText?: string,
	endText?: string,
	rating?: number,
	energy?: number,
	importance?: number,
	price?: string,
	matches?: string,
	modified?: string,
	busyness?: number
	wordcount?: number
	/** Either a number of milliseconds, or a time in HH:MM:SS format. */
	// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
	writeup_time_exact?: number | string,
	// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
	/** Either a number of milliseconds, or a time in HH:MM:SS format. */
	writeup_time_total?: number | string
}
export const PROP_TYPES_ENTRY = PropTypes.shape({
	id: PropTypes.number.isRequired,
	date: PropTypes.string.isRequired,
	saveStatus: PropTypes.oneOf(saveStatusConst),
	status: PropTypes.oneOf(statusConst),
	title: PropTypes.string,
	entry: PropTypes.string,
	summary: PropTypes.string,
	type: PropTypes.number,
	loc: PropTypes.string,
	startText: PropTypes.string,
	endText: PropTypes.string,
	rating: PropTypes.number,
	energy: PropTypes.number,
	importance: PropTypes.number,
	price: PropTypes.string
})

export type Span = {
    id: EntryId
    title: string
    isSpan: 0 | 1
    spansNow: 0 | 1
    startTimestamp: number
    endTimestamp: number
    immediateChildren: EntryId[]
    numInnerImportant: number
    numInnerSpans: number
    numInnerEntries: number
    collapsed?: boolean
}
