/* eslint-disable max-len */

const privacy = `
### Trove privacy policy

Data input into Trove will stay in Trove. We will not disclose or share information with third parties for financial or other reasons.

Within the app itself, your data may, in rare occurrences, need to be accessed for the improvement of the tool itself, to diagnose issues or understand trends. This will _not_ extend to accessing the raw text of your entries unless absolutely necessary.

It should be noted that no Internet tool is 100% impregnable, but care has been taken to prevent security flaws in the software. The biggest attack vector is likely to be your own device: try to avoid using the tool in unsafe locations such as Internet cafés.
`
export default privacy
