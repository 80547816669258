import React, { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { useAuth0 } from '@auth0/auth0-react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSlash, faPlaneCircleExclamation, faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(faUserSlash, faPlaneCircleExclamation, faSquarePlus)

/** Number of seconds to show a short-lived notification, e.g. data version changed */
export const NOTIFICATIONS_SHOWN_FOR = 7

const NavNotificationBarWrapper = (): JSX.Element => {
	const { appState } = useContext(AppContext)
	const { isLoading, isAuthenticated } = useAuth0()
	if (appState.offlineMode)
		return <NavNotificationBar message='You appear to be offline.' className='error' icon='plane-circle-exclamation' />
	if (!isLoading && !isAuthenticated && !appState.disableAuth) {
		return <NavNotificationBar className='error' icon='user-slash'
			message='An error occurred with your session. You may need to log out and in again.' />
	}
	if (appState.dataVersion?.changed) {
		return <NavNotificationBar icon='square-plus' message='New analysis available! Some information may have changed.' />
	}
	return <NavNotificationBar />
}

const NavNotificationBar = (props: {message?: string, className?: string, icon?: IconProp}): JSX.Element =>
	<div id='nav-notification-bar'
		className={`${props.message ? ' nav-notification-bar-shown' : ''}${props.className ? ` ${props.className}` : ''}`}>
		{ props.icon && <FontAwesomeIcon icon={props.icon} /> }
		{props.message}
	</div>

export default NavNotificationBarWrapper
