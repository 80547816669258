import { Link, useHistory } from 'react-router-dom'
import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useContext, useState } from 'react'
import { entriesOnDay } from './helpers'
import { ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS } from './config'
import { Entry, EntryId } from '../Entry/Entry.d'
import { Dispatch } from '../helpers/action/Action.d'
import { EntryContext } from '../contexts/EntryContext'
import EntryStatusIcons from '../Entry/EntryStatusIcons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { EntryAction } from '../helpers/action/EntryAction.d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight,
	faCalendarAlt, faFastBackward, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { formatDate, formatDayOfWeek, ONE_DAY } from '../helpers/format'
import { SetValue, SIMPLE_ENTRY_WRITE_PROPS } from './Write.d'
import { actionInProgress } from '../helpers/action/action'
import { getIdsAction, initialiseInterimEntry } from './actions'
import { AppContext } from '../contexts/AppContext'
import EntryContent from '../Entry/EntryContent'
library.add(faCalendarAlt, faSpinner, faFastBackward, faPlusCircle,
	faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight)

export const NewEntryButton = (props: { date: Date, dispatch: Dispatch<EntryAction> }): JSX.Element =>
	<Link className='entry-write-new' to={'/write/?date=' + formatDate(props.date)}
		onClick={() =>  initialiseInterimEntry(props.dispatch, props.date)}>
		<FontAwesomeIcon icon='plus-circle' /> New entry
	</Link>

export const EntriesOnDay = (props: { date: Date, id: EntryId | undefined, position: 'before' | 'after' }): JSX.Element => {
	const { state } = useContext(EntryContext)
	const ids = entriesOnDay(state, props.date, true)

	const currentEntryIdIndex = !props.id || ids.indexOf(props.id) === -1
		? ids.length : ids.indexOf(props.id)
	const shownIds = props.position === 'before'
		? ids.slice(0, currentEntryIdIndex)
		: ids.slice(currentEntryIdIndex + 1)

	return <>{shownIds.map(id => <EntryContent key={id} entryId={id} hasPrev={id !== shownIds[0]} />) }</>
}

const dateText = (date: Date) => date.toLocaleDateString() == new Date().toLocaleDateString()
	? 'Today'
	: date.toLocaleDateString() == new Date(new Date().getTime() - ONE_DAY * 1000).toLocaleDateString()
		? 'Yesterday' : formatDayOfWeek(date) + ' ' + formatDate(date, 'long')

const isBeforeToday = (date: Date): boolean => date.getTime() < new Date(formatDate()).getTime()

const isAfterToday = (date: Date): boolean => date.getTime() >= new Date(formatDate()).getTime() + (ONE_DAY * 1000)

export const PreviousDay = (props: { from: Date }): JSX.Element =>
	<Link to={'/write/?date=' + formatDate(new Date(props.from.getTime() - ONE_DAY * 1000))}>
		<FontAwesomeIcon icon='angle-left' />
	</Link>

export const NextDay = (props: { from: Date }): JSX.Element | null =>
	isBeforeToday(props.from) ?
		<Link to={'/write/?date=' + formatDate(new Date(props.from.getTime() + ONE_DAY * 1000))}>
			<FontAwesomeIcon icon='angle-right' />
		</Link>
		: null

export const JumpToToday = (props: { from: Date }): JSX.Element | null =>
	isBeforeToday(props.from) || isAfterToday(props.from)
		? <Link to={'/write?date=' + formatDate(undefined)}>
			<FontAwesomeIcon icon={isBeforeToday(props.from) ? 'angle-double-right' : 'angle-double-left'} />
		</Link>
		: null

const EntryDatePicker = (props: { date: Date, shown: boolean, setShown: (shown: boolean) => void}): JSX.Element => {
	const history = useHistory()

	const [initialised, setInitialised] = useState<boolean>(false)
	const [date, setDate] = useState<string>(formatDate(props.date))
	const onChange: ChangeEventHandler<HTMLInputElement> = e => setDate(e.target.value)
	const onFocus: FocusEventHandler<HTMLInputElement> = (e) => {
		if (!initialised) {
			setDate(formatDate(props.date))
			setInitialised(true)
		}
		e.target.click()
	}
	const onBlur = (): void => {
		if (date != formatDate(props.date)) {
			history.push('/write?date=' + date)
		}
		props.setShown(false)
		setInitialised(false)
	}
	const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === 'Enter' || e.key === 'Escape') {
			onBlur()
		}
	}
	return <>
		{ props.shown && <div className='write-date-picker'>
			<input type='date' value={date} autoFocus
				onChange={onChange} onKeyDown={onKeyDown} onBlur={onBlur} onFocus={onFocus} />
		</div>}
	</>
}

const EntryDatePickerButton = (props: { shown: boolean, setShown: (shown: boolean) => void}) =>
	<FontAwesomeIcon icon='calendar-alt' className='write-date-picker' onClick={() => props.setShown(!props.shown)} />

const EntryDateLoading = (props: { date: Date }): JSX.Element => {
	const { state } = useContext(EntryContext)
	return actionInProgress(getIdsAction(props.date), state)
		? <FontAwesomeIcon icon='spinner' className='loading-day' spin={true} />
		: <></>
}

const EntryWriteHeaderPublic = (props: { date: Date }): JSX.Element => {
	const { state } = useContext(EntryContext)
	return <div id='entry-write-header'>
		<h3>
			<Link to='?date=1970-01-01'><FontAwesomeIcon icon='fast-backward' /></Link>
			<NextDay from={props.date} />
			{ props.date.getTime() !== 0 && <PreviousDay from={props.date} /> }
			{ state.idsByQuery?.[JSON.stringify(getIdsAction(props.date))]
				? state?.entries?.[state.idsByQuery?.[JSON.stringify(getIdsAction(props.date))]?.[0]]?.title
				: <span>Nothing found.</span>}
			<EntryDateLoading date={props.date} />
		</h3>
	</div>
}

export const EntryWriteHeader = (props: { date: Date }): JSX.Element => {
	const { isUserType } = useContext(AppContext)
	const [datePickerShown, setDatePickerShown] = useState<boolean>(false)
	if (isUserType('public')) {
		return <EntryWriteHeaderPublic date={props.date} />
	}
	return <h3 id='entry-write-header'>
		<PreviousDay from={props.date} />
		<div>
			{dateText(props.date)}
			<EntryDatePicker date={props.date} shown={datePickerShown} setShown={setDatePickerShown} />
		</div>
		<EntryDateLoading date={props.date} />
		<EntryDatePickerButton shown={datePickerShown} setShown={setDatePickerShown} />
		<NextDay from={props.date} />
		<JumpToToday from={props.date} />
	</h3>
}

export const EntryField = (props: { entry: Entry, setValue: SetValue }): JSX.Element =>
	<div className='entry-write-entry'>
		<textarea className='entry' value={props.entry.entry || ''}
			placeholder={[ENTRY_TYPE_SPAN, ENTRY_TYPE_SEJOUR_PLUS].includes(props.entry.type || 0)
				? 'What happened during this period?'
				: 'What happened today?'}
			onChange={e => props.setValue('entry', e.target.value) } />
		<EntryStatusIcons entry={props.entry} />
	</div>
EntryField.propTypes = SIMPLE_ENTRY_WRITE_PROPS
