import React from 'react'
import App from './App'
import config from './common/auth_config.json'
import { createRoot } from 'react-dom/client'
import ErrorBoundary from './site/ErrorBoundary'
import { Auth0Provider } from '@auth0/auth0-react'
import AppContextProvider from './contexts/AppContext'
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
	<ErrorBoundary>
		<Auth0Provider
			{...config.credentials.provider}
			useRefreshTokens={true}
			cacheLocation="localstorage"
			authorizationParams={{
				...config.credentials.authorizationParams,
				redirect_uri: window.location.origin
			}}>
			<Router>
				<AppContextProvider>
					<App />
				</AppContextProvider>
			</Router>
		</Auth0Provider>
	</ErrorBoundary>
)

serviceWorkerRegistration.register()
