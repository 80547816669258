import React, { FunctionComponent, useContext, useState } from 'react'
import { EntriesOnDay, EntryWriteHeader, NewEntryButton } from './Daily'
import { getCurrentDate, getEntry, getEntryId } from './helpers'
import { EntryContext } from '../contexts/EntryContext'
import { AppContext } from '../contexts/AppContext'
import { useLocation } from 'react-router-dom'
import * as generators from './generators'
import { LastUpdate } from './Write.d'
import FormFields from './FormFields'

const EntryWrite: FunctionComponent = () => {
	const { hasLoginScope } = useContext(AppContext)
	const entryContext = useContext(EntryContext)
	const { state: entries, dispatch } = entryContext
	const { setShortcutsActive } = useContext(AppContext)
	const location = useLocation()

	// Set up component state
	const [lastUpdate, setLastUpdate] = useState<LastUpdate>(0) // timestamp of last keypress

	// Grab all available data.
	const entryId = getEntryId(location, entries)
	const entry = getEntry(entries, entryId)

	const date = getCurrentDate(location, entry)

	// Set up shorthand functions for various forms of state/field/request updates.
	const setValue = generators.setValue(entryId, dispatch)
	const setSaveStatus = generators.setSaveStatus(setValue)
	const saveToApi = () => console.error('Note: manually initiating saving is currently unavailable')

	// On making any change, track typing timers and initiate autosave timer.
	const formUpdated = generators.formUpdated(entry, setValue, lastUpdate, setLastUpdate, setSaveStatus)

	// Output!
	return (
		<div className='entry-write'>
			<EntryWriteHeader date={date} />

			<div className='entry-write-day'>
				<EntriesOnDay date={date} position='before' id={entryId} />
				{ hasLoginScope('write') && entry.id && <form onSubmit={(e) => { saveToApi(); e.preventDefault() }}
					onChange={() => formUpdated()}
					onFocus={() => setShortcutsActive(false)}
					onBlur={() => setShortcutsActive(true)}>
					<FormFields entry={entry} setValue={setValue} formUpdated={formUpdated} />
				</form> }
				<EntriesOnDay date={date} position='after' id={entryId} />
			</div>
			{ hasLoginScope('write') && <NewEntryButton dispatch={dispatch} date={date} />}
		</div>)
}

export default EntryWrite
