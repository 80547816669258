import { matchPath } from 'react-router'
import { History, Location } from 'history'
import { Dispatch, PrimaryAction } from './action/Action.d'
import { LoginScope } from '../contexts/AppContext.d'

type Shortcut = {
	/** The URL to which the user will be taken */
	target: string
	/** The action to be taken if the user is already at the target */
	then?: Shortcut
	strict?: boolean
	/** Only act on this shortcut if the user has this privilege */
	scope?: LoginScope
}
type KeyboardShortcuts = {
	[ shortcut: string ]: Shortcut
}

type SecondaryPath = {
	page: string
	visualOnly?: boolean
}
type SecondaryPaths = {
	[ page: string ]: SecondaryPath
}

// Secondary paths count as their primaries for PageNavBar highlights, and
//  and (unless visualOnly) for shortcuts to remember pages' current section.
const secondaryPaths: SecondaryPaths = {
	'/entry': { page: '/entries' },
	'/write': { page: '/entries', visualOnly: true },
	'/person': { page: '/explore' },
	'/admin': { page: '/settings', visualOnly: true }
}

// Map keys to pages. Strict shortcuts only work after the page has been visited.
const keyboardShortcuts: KeyboardShortcuts = {
	x: { target: '/explore', then: { target: '/person', strict: true } },
	// e: { target: '/entries', then: { target: '/entry', strict: true } },
	w: { target: '/write' },
	a: { target: '/admin', scope: 'admin' },
	r: { target: '/search' },
	s: { target: '/settings' }
}

export const getShortcut = (key: string, path: string): Shortcut | undefined =>
	keyboardShortcuts[key] &&
		(isCurrentPage(path, keyboardShortcuts[key].target)
			? keyboardShortcuts[key].then : keyboardShortcuts[key])

export const isCurrentPage = (query: string, current: string, { allowSecondary = false, shortcut = false } = {}): boolean =>
	query?.match(':')
		? !!matchPath(current, query)
		: (allowSecondary || shortcut
			? getBasePageName(query, shortcut) === getBasePageName(current, shortcut)
			: getPageSectionOfPath(query) === getPageSectionOfPath(current))

export const getQueryParam = (location: Location, paramName: string): string | null =>
	new URLSearchParams(location.search).get(paramName)

export const isPageUrl = (path: string): boolean => {
	if (typeof path !== 'string') { return false }
	return path !== '/' && (path.match(/[/]/g) || []).length === 1
}

export const getPageSectionOfPath = (path: string | null | undefined): string => {
	if (typeof path !== 'string') { return '/' }
	const pageSection = path.substr(0, path.search(/.\//) + 1)
	return pageSection === '' ? path : pageSection
}

export const getBasePageName = (path: string, shortcut = false): string => {
	const page = getPageSectionOfPath(path)
	if (page in secondaryPaths && (!shortcut || !secondaryPaths[page].visualOnly)) {
		return secondaryPaths[page].page
	}
	return page
}

const redirectUrl = (feature: string, code: string) => {
	switch (feature) {
	case 'fitbit':
		if (!code) { return '/settings' }
		return '/settings?redirected=fitbit'
	default:
		return '/'
	}
}

/** Perform any redirects necessary, and return whether any were needed. */
export const redirect = (feature: string | undefined,
	dispatch: Dispatch<PrimaryAction>, appDispatch: Dispatch<PrimaryAction>,
	location: Location, history: History): boolean => {
	if (!feature) { return false }
	const code = new URLSearchParams(location.search)?.get('code')
	if (feature === 'fitbit') {
		if (code) {
			dispatch({ type: 'SYNC_FITBIT_AUTH_CALLBACK', api: 'fitbit', code, appDispatch })
		}
	} else {
		console.error(`Unknown redirect '${feature}'`)
		return false
	}
	if (!code) {
		console.error('No redirect code provided')
		return false
	}
	history.push(redirectUrl(feature, code))
	return true
}
