import React, { Context, createContext, useContext, useEffect, useReducer } from 'react'
import { ChildrenOnlyProps, ContextConfig, InfiniteScrollConfig } from '../helpers/context.d'
import { checkCacheValidity, defaultContextConfig, initialiseState } from '../helpers/context'
import { generateDispatcher } from '../helpers/dispatch'
import { SuperDispatch } from '../helpers/dispatch.d'
import { AppContext } from '../contexts/AppContext'
import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import PropTypes from 'prop-types'
import { AnyAction, Dispatch } from '../helpers/action/Action.d'
import PersonThumbnail from '../Person/PersonThumbnail'
import PersonContent from '../Person/PersonContent'
import { PersonContextProvided, PersonContextState } from './PersonContext.d'
import { InfiniteScrollContextProvided } from '../common/InfiniteScroll.d'
import { PersonDispatcher, PersonReducer } from '../reducers/PersonReducer'

const defaultContextProvided: PersonContextProvided = {
	state: {
		people: {},
		graphs: {}
	},
	dispatch: () => { throw new Error('Dummy PersonContext used') }
}

export const PersonContext = createContext<PersonContextProvided>(defaultContextProvided)

export const contextConfig: ContextConfig<PersonContextState> & InfiniteScrollConfig = {
	...defaultContextConfig,
	context: PersonContext as unknown as Context<InfiniteScrollContextProvided>,
	localStorageLocation: 'people',
	contextDataLocation: ['people'],
	apiEndpointIds: 'people',
	apiEndpointDetails: 'person',
	freshState: {},
	actionTypes: {
		loadDetails: ['LOAD_MORE_PEOPLE', 'GET_PERSON'],
		loadIds: ['QUERY_PEOPLE']
	},
	// eslint-disable-next-line react/display-name
	renderThumbnail: (personId) => <PersonThumbnail key={personId} personId={personId} />,
	// eslint-disable-next-line react/display-name
	renderDetails: (personId) => <PersonContent key={personId} personId={personId} />
}

const PersonContextProvider = (props: ChildrenOnlyProps): JSX.Element => {
	const appContext = useContext(AppContext)

	// Initialise.
	const [state, reducerDispatch] = useReducer<typeof PersonReducer, PersonContextState>(PersonReducer, defaultContextProvided.state,
		initialiseState(contextConfig, appContext.appState))

	const dispatch = generateDispatcher(reducerDispatch as Dispatch<AnyAction>, PersonDispatcher as SuperDispatch, appContext)
	checkCacheValidity(state, dispatch, appContext.appState)

	const location = useLocation().pathname
	const pathDetails = matchPath<{ personId?: string }>(location, '/person/:personId')
	if (pathDetails?.params?.personId &&
		Number(pathDetails.params.personId) && Number(pathDetails.params.personId) !== state.selectedPerson) {
		dispatch({ type: 'SELECT_PERSON', personId: Number(pathDetails.params.personId) || pathDetails.params.personId })
	}

	// Save any updates in local storage.
	useEffect(() => localStorage.setItem('people', JSON.stringify(state)), [state])

	return <PersonContext.Provider value={{ state, dispatch }}>
		{props.children}
	</PersonContext.Provider>

}
PersonContextProvider.propTypes = {
	children: PropTypes.node.isRequired
}

export default PersonContextProvider
