import * as config from './config'
import { parseHms } from '../helpers/format'
import { Dispatch } from '../helpers/action/Action.d'
import { LastUpdate, SetLastUpdate } from './Write.d'
import { EntryAction } from '../helpers/action/EntryAction.d'
import { Entry, EntryId, NotAnEntry } from '../Entry/Entry.d'
import { FormUpdated, SetSaveStatus, SetValue } from './Write.d'

/* These functions generate others which are in turn used by the Write controller */

export const setValue = (entryId: EntryId | undefined, dispatch: Dispatch<EntryAction>): SetValue =>
	entryId
		? (field, value) =>
			dispatch({ type: 'CACHE_WRITE_ENTRY', entry: { id: entryId, [field]: value } })
		: () => { throw Error('Updating a nonexistent entry') }

export const setSaveStatus = (setValue: SetValue): SetSaveStatus =>
	(saveStatus) => setValue('saveStatus', saveStatus)

/** When the form is updated, trigger the autosave timer */
export const formUpdated = (entry: Entry | NotAnEntry, setValue: SetValue,
	lastUpdate: LastUpdate, setLastUpdate: SetLastUpdate,
	setSaveStatus: SetSaveStatus, fixedNow?: Date): FormUpdated => {
	const addToTimer = (timer: keyof Entry, increment: number) => {
		const fieldValue = entry[timer] as Entry[keyof Entry]
		setValue(timer, increment + (fieldValue
			? (typeof fieldValue === 'string'
				? parseHms(fieldValue)
				: fieldValue)
			: 0))
	}

	return () => {
		const now = (fixedNow ? fixedNow : new Date()).getTime()
		const elapsedTime = lastUpdate && now - lastUpdate
		if (elapsedTime != null && elapsedTime < config.TIMER_LIMIT_EXACT * 1000) {
			addToTimer('writeup_time_exact', elapsedTime)
		}
		if (elapsedTime != null && elapsedTime < config.TIMER_LIMIT_TOTAL * 1000) {
			addToTimer('writeup_time_total', elapsedTime)
		}
		setLastUpdate(now)
		if (!['saving', 'waiting', 'conflicted'].includes(entry.saveStatus || '')) {
			setSaveStatus('waiting')
		}
	}
}
