import React, { useContext } from 'react'
import { EntryContext } from '../contexts/EntryContext'
import { EntryRetriever } from '../reducers/EntryReducer'
import PropTypes from 'prop-types'
import { Span } from './Entry.d'
import { faCalendarWeek, faChartSimple, faIndent, faRotateRight, faSquareCaretDown, faSquareCaretRight, faStar
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { calculateIntervalBetween, formatDate } from '../helpers/format'
import Graph from '../common/Graph'
import { PersonContext } from '../contexts/PersonContext'
import { PersonRetriever } from '../reducers/PersonReducer'
library.add(faSquareCaretDown, faSquareCaretRight, faIndent, faRotateRight, faChartSimple, faCalendarWeek, faStar)

const TOP_LEVEL_SPAN_ID = 0
const MIN_DURATION_FOR_LINE_GRAPH = 30
const isSpanCollapsed = (span: Span): boolean => span.collapsed !== undefined ? span.collapsed : !span.spansNow
const ONE_DAY_SECONDS = 24 * 60 * 60

const EntrySpans = (): JSX.Element => {
	const { state } = useContext(EntryContext)
	const isLoading = state.inProgress && JSON.stringify({ type: 'RETRIEVE_SPANS' }) in state.inProgress
	return <>
		<h3>Timespan entries {isLoading && <FontAwesomeIcon icon='spinner' spin={true} />}</h3>
		<div className='tip'>
			Here you can view all <FontAwesomeIcon icon='calendar-week'/> span-type entries.
		</div>
		<EntryRetriever>
			<EntrySpansContent entryId={TOP_LEVEL_SPAN_ID} />
		</EntryRetriever>
	</>
}

export const EntrySpansContent = (props: { entryId: number }): JSX.Element => {
	const { state } = useContext(EntryContext)
	const span = state.spans?.[props.entryId]
	if (span === undefined) {
		return <></>
	}
	return <div className={span.id === TOP_LEVEL_SPAN_ID ? undefined : 'entry-span'}>
		{ props.entryId !== TOP_LEVEL_SPAN_ID && <EntrySpan entryId={props.entryId}/>}
		<div className={'entry-span-children' + (isSpanCollapsed(span) ? ' entry-span-collapsed' : '')}>
			{span.immediateChildren.map(entryId => <EntrySpansContent key={entryId} entryId={entryId} />)}
		</div>
	</div>
}
EntrySpansContent.propTypes = {
	entryId: PropTypes.number.isRequired
}

const EntrySpanGraphs = (props: { start: number, end: number}): JSX.Element => {
	const duration = calculateIntervalBetween(props.start * 1000, props.end * 1000) / (24 * 60 * 60)
	return <>
		<PersonRetriever>
			<Graph context={PersonContext} action={{
				type: 'GRAPH_PEOPLE_CLOUD_BETWEEN_DATES',
				start: props.start,
				end: props.end
			}} />
		</PersonRetriever>
		{duration > MIN_DURATION_FOR_LINE_GRAPH &&
			<PersonRetriever>
				<Graph context={PersonContext} action={{
					type: 'GRAPH_PEOPLE_LINE_BETWEEN_DATES',
					start: props.start,
					end: props.end
				}} />
			</PersonRetriever>}
	</>
}

const EntrySpan = (props: { entryId: number }): JSX.Element => {
	const { state, dispatch } = useContext(EntryContext)
	const { state: personState, dispatch: personDispatch } = useContext(PersonContext)
	const span = state.spans?.[props.entryId]
	if (span === undefined) {
		return <></>
	}
	const updatedSpan: { title?: string } = state.write?.[props.entryId] || state.entries?.[props.entryId] || {}
	const graphsShown = span.startTimestamp == personState.spanGraphShown?.start
		&& span.endTimestamp == personState.spanGraphShown?.end
	const selectSpanForGraphing = () => {
		personDispatch({ type: 'SET_SHOWN_SPAN_GRAPH', start: span.startTimestamp, end: span.endTimestamp })
	}
	const dateString = <>
		{formatDate(span.startTimestamp * 1000, 'long')}
		{((!!span.isSpan && span.endTimestamp > span.startTimestamp + ONE_DAY_SECONDS) &&
		' to ' + (span.spansNow ? 'now' : formatDate(span.endTimestamp * 1000, 'long')))}
	</>

	const title = updatedSpan.title || span.title
	return <>
		<FontAwesomeIcon icon={span.isSpan ? 'calendar-week' : 'star'} />{' '}
		<Link to={'/write?entryId=' + props.entryId}>{title || dateString}</Link>

		{ span.numInnerImportant > 0 && <><FontAwesomeIcon icon="star" /> {span.numInnerImportant}</>}
		{ span.numInnerSpans > 0 && <><FontAwesomeIcon icon="calendar-week" /> {span.numInnerSpans}</>}
		{ span.isSpan > 0 && <><FontAwesomeIcon icon="chart-simple" className='clickable' onClick={() => selectSpanForGraphing()}/></>}
		{ span.immediateChildren.length > 0 && <FontAwesomeIcon
			className='toggle'
			onClick={() => dispatch({ type: 'ENTRY_SPAN_TOGGLE', entryId: span.id })}
			icon={isSpanCollapsed(span) ? 'square-caret-right' : 'square-caret-down'} />}
		{title && <p className='entry-span-dates'>{dateString}</p>}
		{ graphsShown && <EntrySpanGraphs start={span.startTimestamp} end={span.endTimestamp} />}
	</>
}
EntrySpan.propTypes = {
	entryId: PropTypes.number.isRequired
}

export default EntrySpans
