import { Location } from 'history'
import { getIdsAction } from './actions'
import { formatDate } from '../helpers/format'
import { IsDataPresent } from '../common/Retriever'
import { Entry, EntryId, NotAnEntry } from '../Entry/Entry.d'
import { EntryContextState } from '../contexts/EntryContext.d'

/** Inequalities between these fields don't cause entries to not be equal */
const SPECIAL_ENTRY_FIELDS = ['saveStatus', 'timeout', 'modified',
	'writeup_time_exact', 'writeup_time_total', 'id', 'user', 'visibility', 'status']

/** Checks whether any of the (relevant) properties of a don't match those of b */
export const areEntriesEqual = (a: Entry | undefined, b: Entry | undefined, keysStrictlyMatch = false): boolean => {
	if (!a || !b)
		return false
	// The API might return YYYY-MM-DD or a full timestamp with HH:MM:SS.sssZ
	const aNorm = { ...a, date: a.date?.substring(0, 10), entry: a.entry || '' }
	const bNorm = { ...b, date: b.date?.substring(0, 10), entry: b.entry || '' }
	const nonMatches = Object.keys(aNorm)
		.filter(key => !SPECIAL_ENTRY_FIELDS.includes(key))
		.filter(key => {
			const bothEmpty = !aNorm[key] && !bNorm[key]
			const valuesMatch = bothEmpty || aNorm[key] === bNorm[key]
			const keyMissing = !bothEmpty && !(key in bNorm)
			return (keysStrictlyMatch && keyMissing)
				|| (keysStrictlyMatch || !keyMissing) && !valuesMatch
		})
	return nonMatches.length === 0
}

export const isEntryBlank = (entry: Entry | NotAnEntry | undefined): boolean =>
	entry === undefined || !entry.id ? true : areEntriesEqual(entry, { id: 0, date: entry.date, type: 42 }, true)

export const isInterimEntry = (entryId: EntryId | undefined): boolean => !!entryId && entryId < 0

export const areEntriesPresentForDay = (): IsDataPresent<EntryContextState> =>
	(state, props) => entriesOnDay(state, props.date as Date).length > 0

export const getParamEntryId = (location: Location): EntryId | undefined => {
	const entryIdString = new URLSearchParams(location.search).get('entryId')
	return entryIdString
		? Number(entryIdString)
		: undefined
}

export const getEntryId = (location: Location, entries: EntryContextState): EntryId | undefined =>
	getParamEntryId(location)
		? getParamEntryId(location)
		: entries.currentlyWriting

export const getEntry = (entries: EntryContextState, entryId: EntryId | undefined): Entry | NotAnEntry =>
	!entryId ? {} : entries.write?.[entryId] || entries.entries?.[entryId] || {}

export const hasParamDate = (location: Location): boolean =>
	!isNaN(new Date(new URLSearchParams(location.search).get('date') || '').getTime())

export const getParamDate = (location: Location): Date | undefined => {
	const paramDateString = new URLSearchParams(location.search).get('date')
	return paramDateString && !Number.isNaN(new Date(paramDateString).getTime())
		? new Date(paramDateString) : undefined
}

/** Work out what date the current entry is for */
export const getCurrentDate = (location: Location, entry: Entry | NotAnEntry): Date =>
	hasParamDate(location)
		? getParamDate(location) || new Date()
		: !Number.isNaN(new Date(entry.date || '-').getTime())
			? new Date(entry.date || '-')
			: new Date()

export const lastEntryOnDay = (state?: EntryContextState, date?: Date | string | undefined): EntryId | undefined => {
	if (!state || !date) {
		return undefined
	}
	const entries = entriesOnDay(state, date)
	if (entries.length === 0) {
		return undefined
	}
	const interimEntries = entries.filter(e => isInterimEntry(e))
	return interimEntries.length > 0
		? interimEntries[ interimEntries.length - 1]
		: entries[ entries.length - 1]
}

export const entriesOnDay = (state: EntryContextState, date: Date | string, allowPartial = false): EntryId[] => {
	const dateString = formatDate(date)
	const allSavedEntries: EntryId[] = state.idsByQuery?.[JSON.stringify(getIdsAction(date))] || []
	const savedEntries = allowPartial
		? allSavedEntries
		: allSavedEntries.filter(entryId => entryId in (state.entries || {}))
	const interimEntries = Object.values(state.write || {})
		.filter(entry => entry.date === dateString)
		.filter(entry => !savedEntries.includes(entry.id))
		.map(entry => entry.id)
	return savedEntries.concat(interimEntries)
}
