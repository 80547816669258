import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import ErrorMessage from '../common/ErrorMessage'
import { EntryContext, contextConfig } from '../contexts/EntryContext'
import InfiniteScrollWithCache, { infiniteScrollConfig } from '../common/InfiniteScroll'
import { InfiniteScrollParams } from '../common/InfiniteScroll.d'

export const EntryThumbnailListWithField = (props: Partial<InfiniteScrollParams>): JSX.Element => {
	const { state: entries } = useContext(EntryContext)
	const urlParams: { field: string, value?: string } = useParams()
	if (!urlParams.field) {
		return <ErrorMessage error={{ message: 'No field specified. This is an internal error.' }} />
	}
	const params = { ...urlParams, ...props }

	return (
		<div className='thumbnail-list entry-thumbnail-list'>
			<InfiniteScrollWithCache {...infiniteScrollConfig(contextConfig, entries, params)} />
		</div>
	)
}

export const EntryThumbnailListWithDate = (): JSX.Element => {
	const { state: entries } = useContext(EntryContext)
	const { date } = useParams<{ date: string }>()
	const params = { field: 'date', value: date, comparison: 'exactly' as const }
	return (
		<div className='thumbnail-list entry-thumbnail-list'>
			<InfiniteScrollWithCache {...infiniteScrollConfig(contextConfig, entries, params)} />
		</div>
	)
}
