/* eslint-disable max-len */

const more = `

Trove supports a number of powerful features for users who wish to go beyond simple hashtags and handles.

### 'Iron' links

In normal usage, the 'strength' of a link between a handle and a hashtag is based on the proportions of their entries in which they both occur. Thus, if one entry contains \`@John\` and \`#fun\` together while another has only \`@John\`, the strength of the link between the two would be less than 100%.

For some tags, however, this may not be desirable, so you can use an _iron_ link. If you wish \`@John\` to be permanently, strongly linked with \`#family\`, you can add an exclamation mark to the tag: \`#!family\`. Be careful: this will bind to _all_ handles on the same line of text, and can only be undone by altering the entries to remove the _!_.

### Names

In addition to hashtags and handles, you can write simple names, such as _John Smith_, into your entries. However, to avoid false positives like _Costa Coffee_ from being recognised accidentally, these names must follow certain rules.

- A name will always be recognised if it includes a surname in brackets: this is known as a _full name_.
    -  \`John (Smith)\`, \`Jane (von Oberwald)\`
- A full name can include an (arbitrary) two- or three-letter country code.
    - \`JohnUK (Smith)\`, \`JaneDE (von Oberwald)\`

Alternate forms of names will only be matched if they relate to a full name which exists anywhere, in entries before _or after_ the alternate form.

- A name without brackets requires a full name with brackets.
    - \`John Smith\` will only be matched if \`John (Smith)\` exists
- Shortened surnames are not _yet_ supported.
    - \`John Sm\` will only be matched if \`John (Sm)\` exists, not \`John (Smith)\`
- A first name alone requires a full name.
    - \`John\` will only be matched if \`John (Smith)\` or \`JohnUK (Smith)\` exists
- A country code will allow _any_ first name to be recognised.
    - \`JoeUS\` will be matched if either \`Joe (Smith)\` or \`JanetUS (Jones)\` exists

Alternate forms will be combined into full names where possible. Thus, five mentions of \`John\` plus by one of \`JohnUK (Smith)\` in one will be analysed as six references to John Smith. If \`John (Jones)\` is subsequently mentioned, any later mentions of \`John\` alone will be assumed to refer to John Jones until \`John (Smith)\`, \`John Smith\` or \`JohnUK\` is mentioned again.
`
export default more
