import { FunctionComponent } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import { PROP_TYPES_ENTRY } from '../Entry/Entry.d'

/* Components */

export const SIMPLE_ENTRY_WRITE_PROPS = {
	entry: PROP_TYPES_ENTRY.isRequired,
	setValue: PropTypes.func.isRequired
}
export type SimpleEntryWriteComponent = FunctionComponent<InferProps<typeof SIMPLE_ENTRY_WRITE_PROPS>>
export const ENTRY_WRITE_UPDATE_PROPS = {
	...SIMPLE_ENTRY_WRITE_PROPS,
	formUpdated: PropTypes.func.isRequired
}
export type EntryWriteUpdateComponent = FunctionComponent<InferProps<typeof ENTRY_WRITE_UPDATE_PROPS>>

/* React state */

export type LastUpdate = number | null
export type SetLastUpdate = React.Dispatch<React.SetStateAction<LastUpdate>>

/* Actions */

/** Update context state with a new value for this field of this entry */
export type SetValue = (field: string, value: string | number | undefined) => void

/** Update saveStatus, the field which governs the Save button's icon */
export type SetSaveStatus = (saveStatus: string) => void

/** Fired whenever any form element is updated: saves any changes in context state and triggers autosave. */
export type FormUpdated = () => void
