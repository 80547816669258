import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { Entry, EntryStatus, PROP_TYPES_ENTRY } from './Entry.d'
import { faSpinner, faExclamationTriangle, faHourglassHalf, faBan, faCheck } from '@fortawesome/free-solid-svg-icons'
import { isInterimEntry } from '../Write/helpers'
import { actionInProgress } from '../helpers/action/action'
import { getEntriesActionKey } from '../Write/actions'
import { EntryContext } from '../contexts/EntryContext'
library.add(faSpinner, faExclamationTriangle, faHourglassHalf, faBan, faCheck)

const getStatus = (status?: EntryStatus): { icon: IconProp, title: string } => {
	switch (status) {
	case 'saving':
		return { icon: 'spinner', title: 'Saving' }
	case 'failed':
		return { icon: 'exclamation-triangle', title: 'Retrying' }
	case 'conflicted':
		return { icon: 'exclamation-triangle', title: 'Conflict' }
	case 'waiting':
		return { icon: 'hourglass-half', title: 'Waiting' }
	case 'deleted':
		return { icon: 'ban', title: 'Deleted' }
	case 'deleting':
		return { icon: 'spinner', title: 'Deleting' }
	case 'refreshing':
		return { icon: 'spinner', title: 'Refreshing' }
	case 'saved':
	default:
		return { icon: 'check', title: 'Saved' }
	}
}

type EntryStatusIconProps = {
  entry: Entry
}

const EntryStatusIcons = (props: EntryStatusIconProps): JSX.Element => {
	const { state } = useContext(EntryContext)
	if (isInterimEntry(props.entry.id) && props.entry.saveStatus === 'saved') {
		return <></>
	}
	const status = getStatus(
		props.entry.status === 'deleted' && !props.entry.saveStatus
			? 'deleted'
			: !['saving'].includes(props.entry.saveStatus || 'saved ') && actionInProgress(getEntriesActionKey(props.entry.id), state)
				? 'refreshing'
				: props.entry.saveStatus)
	return <div className='entry-write-status'>
		{status.title}
		<FontAwesomeIcon icon={status.icon} spin={status.icon === 'spinner'} />
	</div>
}

EntryStatusIcons.propTypes = {
	entry: PROP_TYPES_ENTRY
}

export default EntryStatusIcons
