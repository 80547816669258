import React, { useContext, useState } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import PersonThumbnail from './PersonThumbnail'
import { PersonContext } from '../contexts/PersonContext'
import { PersonRetriever } from '../reducers/PersonReducer'

const PersonLink = (props: InferProps<typeof personLinkPropTypes>): JSX.Element => {
	const { state } = useContext(PersonContext)
	const [activeStatus, setActiveStatus] = useState<'hovered' | 'pinned' | null>(null)

	const text = (props.text ? props.text : state.people?.[Number(props.personId)]?.name) || ''
	const prettyText = text
		.replace(/ +[(][^()]+[)]$/, '')
		.replace(/-[0-9]( |$)/, '$1')
		.replace(/[A-Z]{2,3}(| $)/, '$1')
	const onMouseOver = () => {
		if (activeStatus !== 'pinned') { setActiveStatus('hovered') }
	}
	const onMouseOut = () => {
		if (activeStatus !== 'pinned') { setActiveStatus(null) }
	}
	const onClick: React.MouseEventHandler = (event) => {
		// eslint-disable-next-line dot-notation
		if (!event.target['classList'].contains('entry-person-link-text')) { return }
		if (activeStatus === 'pinned') { return setActiveStatus('hovered') }
		return setActiveStatus('pinned')
	}
	return (
		<span className='entry-person-link' onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={onClick}>
			<span className='entry-person-link-text'>
				{prettyText}
			</span>
			<div className='thumbnail-wrapper person-thumbnail-wrapper'>
				<PersonRetriever inactive={!activeStatus}>
					<PersonThumbnail personId={props.personId} />
				</PersonRetriever>
			</div>
		</span>)
}

const personLinkPropTypes = {
	personId: PropTypes.string.isRequired,
	text: PropTypes.string
}
PersonLink.propTypes = personLinkPropTypes

export default PersonLink
