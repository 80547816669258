import React, { useContext } from 'react'
import { SettingsContext } from '../contexts/SettingsContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { IconProp, RotateProp } from '@fortawesome/fontawesome-svg-core'
import { actionFailed, actionInProgress, conditionalDispatch } from '../helpers/action/action'
import { SettingsAction } from '../helpers/action/SettingsAction.d'
import { BUTTON_REFRESH, EXTERNAL_API_LINK, EXTERNAL_API_UNLINK, STATUS_ICON_SUCCESS,
	INSTANCE_CONTROL_STOPPED, INSTANCE_CONTROL_STARTED, INSTANCE_CONTROL_STARTING,
	INSTANCE_CONTROL_STOPPING, InstanceStatusCode, STATUS_ICON_FAILED, STATUS_ICON_LOADING,
	STATUS_ICON_WORKING, STATUS_PREPARE_PENDING } from '../contexts/SettingsContext.d'

const codeToIcon = (icon: IconProp | InstanceStatusCode | undefined): IconProp => {
	if (typeof icon === 'string') {
		return icon
	}
	switch (icon) {
	case INSTANCE_CONTROL_STOPPED:
	case false:
		return 'toggle-off'
	case BUTTON_REFRESH:
		return 'sync-alt'
	case EXTERNAL_API_LINK:
		return 'link'
	case EXTERNAL_API_UNLINK:
		return 'unlink'
	case STATUS_ICON_SUCCESS:
		return 'check-circle'
	case INSTANCE_CONTROL_STARTING:
	case INSTANCE_CONTROL_STOPPING:
	case STATUS_PREPARE_PENDING:
	case STATUS_ICON_WORKING:
		return 'cog'
	case STATUS_ICON_FAILED:
		return 'exclamation-triangle'
	case INSTANCE_CONTROL_STARTED:
	case true:
		return 'toggle-on'
	case STATUS_ICON_LOADING:
		return 'spinner'
	default:
		return 'question-circle'
	}
}

const codeToIconOverlay = (icon: IconProp | InstanceStatusCode | undefined): IconProp | undefined => {
	if (typeof icon === 'string') {
		return undefined
	}
	switch (icon) {
	case INSTANCE_CONTROL_STARTING:
		return 'play'
	case INSTANCE_CONTROL_STOPPING:
		return 'hand'
	default:
		return undefined
	}
}

const iconToClass = (icon: IconProp): string => {
	switch (icon) {
	case 'toggle-on':
	case 'plug':
	case 'check-circle':
		return 'enabled'
	case 'toggle-off':
	case 'power-off':
	case 'exclamation-triangle':
		return 'disabled'
	case 'spinner':
		return 'loading'
	case 'question-circle':
		return 'unknown'
	default:
		return ''
	}
}

const SettingsIcon = (props: SettingsIconProps): JSX.Element => {
	const { state, dispatch } = useContext(SettingsContext)

	if (props.icon === STATUS_ICON_FAILED) { return <></> }

	const icon = codeToIcon(props.icon)
	const overlay = codeToIconOverlay(props.icon)

	const classes =
		(props.className ? props.className + ' ' : '') +
		(props.action ? 'has-action ' : '') +
		(icon === 'sync-alt' ? 'refresh ' : '') +
		(actionInProgress(props.action, state) ? 'loading ' : '') +
		(actionFailed(props.action, state) && icon !== 'exclamation-triangle'
			? 'failed'
			: actionInProgress(props.action, state)
				? 'spinner'
				: (iconToClass(icon)))

	const onClick = props.onClick || (() => {
		if (!props.action) { return }
		conditionalDispatch(props.action, state, dispatch, { retryFailed: true })
	})

	const primaryIcon = <FontAwesomeIcon
		icon={icon}
		rotation={props.rotation}
		className={classes}
		spin={icon === 'spinner' || icon === 'cog'}
		onClick={ onClick }	/>
	return overlay
		? <div className="fa-layers fa-fw">
			{ primaryIcon }
			<FontAwesomeIcon icon={overlay} className='fa-layers-top-right' transform={'up-6 shrink--7'} />
		</div>
		: primaryIcon
}

type SettingsIconProps = {
	icon?: InstanceStatusCode | IconProp
	action?: SettingsAction
	onClick?: React.MouseEventHandler<SVGSVGElement>
	rotation?: RotateProp
	className?: string
}

const settingsIconPropTypes = {
	icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	action: PropTypes.shape({ type: PropTypes.string.isRequired }),
	onClick: PropTypes.func,
	rotation: PropTypes.number,
	className: PropTypes.string
}
SettingsIcon.propTypes = settingsIconPropTypes

export default SettingsIcon
