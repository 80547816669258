import React, { useContext, useState } from 'react'
import { ENTRY_WRITE_UPDATE_PROPS, EntryWriteUpdateComponent, FormUpdated, SetValue } from './Write.d'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { VoiceAction } from '../helpers/action/VoiceAction'
import { VoiceContext } from '../contexts/VoiceContext'
import { Dispatch } from '../helpers/action/Action.d'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy, faFilePen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { VoiceContextState } from '../contexts/VoiceContext.d'
import { formatTimeInterval } from '../helpers/format'
library.add(faCopy, faFilePen, faTrash)

/** Currently the API only accepts one mime-type */
const ACCEPTED_MIME_TYPES = ['audio/webm']

const startRecording = (
	setStream: (stream: MediaStream | null) => void,
	dispatch: Dispatch<VoiceAction>
) => async () => {
	const mimeTypes = ACCEPTED_MIME_TYPES.filter((type) => MediaRecorder.isTypeSupported(type))
	if (mimeTypes.length === 0) {
		return alert('Audio recording not supported by your browser')
	}
	const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
	setStream(stream)
	const recordingStart = new Date().getTime()
	const recorder = new MediaRecorder(stream, { mimeType: mimeTypes[0] })
	recorder.addEventListener('dataavailable', (event) => {
		if (event.data.size == 0) {
			console.warn('No transcription data available') // eslint-disable-line no-console
			return
		}
		setStream(null)
		const duration = new Date().getTime() - recordingStart
		const reader = new FileReader()
		reader.readAsDataURL(event.data)
		reader.onloadend = function() {
			const base64String = reader.result
			if (duration < 0.5) {
				console.warn('Recording lasted less than 0.5 seconds. Not saving.') // eslint-disable-line no-console
			} else if (typeof base64String === 'string') {
				dispatch({ type: 'VOICE_CACHE', recording: base64String, duration })
			} else {
				console.error(`Unexpected type ${typeof base64String} of recording. Not saving.`)
			}
		}
	})
	recorder.start()
}

const stopRecording = (stream: MediaStream | null) => () => stream!.getTracks().forEach((track) => track.stop())

/** Provide a button to click to start recording audio */
export const VoiceRecorder = (): JSX.Element => {
	const { state, dispatch } = useContext(VoiceContext)
	const [stream, setStream] = useState<MediaStream | null>(null)
	const isRecording = () => stream !== null

	if (state.voiceData && !state.failed?.[JSON.stringify({ type: 'VOICE_RETRIEVE_TRANSCRIPTION' })]) {
		return <span className='tip'>
			<FontAwesomeIcon icon='spinner' className='loading' spin={true} />
			{state.voiceDuration && <>Transcribing {formatTimeInterval((state.voiceDuration ?? 0) / 1000)}...</>}
			{!state.voiceDuration && <>Transcription in progress...</> }
		</span>
	}

	return <span className='clickable' onClick={isRecording() ? stopRecording(stream) : startRecording(setStream, dispatch)}>
		<FontAwesomeIcon icon={isRecording() ? 'circle-stop' : 'microphone'} />
		{' '}{isRecording() ? 'Transcribe' : 'Record'}
	</span>
}

/** Copy the transcript to the clipboard */
export const copyHandler = (
	state: VoiceContextState,
	setCopied: (copied: boolean) => void,
	setConfirmDelete: (deleted: boolean) => void): () => void => () => {
	setConfirmDelete(false)
	if (!state.voiceTranscript) {
		return
	}
	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	navigator.clipboard.writeText(state.voiceTranscript)
	setCopied(true)
}

/** Append the transcript to the end of the current entry */
export const appendHandler = (
	state: VoiceContextState,
	appended: boolean,
	setAppended: (appended: boolean) => void,
	entry: Partial<Parameters<EntryWriteUpdateComponent>[0]['entry']>,
	setValue: SetValue,
	formUpdated: FormUpdated,
	setConfirmDelete: (confirm: boolean) => void
): () => void => () => {
	if (appended || !state.voiceTranscript) {
		return
	}
	formUpdated()
	setValue('entry', `${entry.entry ? `${entry.entry}\n` : ''}${state.voiceTranscript}`)
	setAppended(true)
	setConfirmDelete(false)
}

/** Confirm transcript deletion, then trigger an API call to do it */
export const deleteHandler = (confirmDelete: boolean,
	setConfirmDelete: (confirm: boolean) => void,
	dispatch: Dispatch<VoiceAction>): () => void => () => {
	if (!confirmDelete) {
		setConfirmDelete(true)
	} else {
		setConfirmDelete(false)
		dispatch({ type: 'VOICE_MARK_DELETE' })
	}
}

/** Output a voice transcript, plus buttons to interact with it */
export const VoiceTranscript: EntryWriteUpdateComponent = (props) => {
	const { state, dispatch } = useContext(VoiceContext)
	const [copied, setCopied] = useState(false)
	const [appended, setAppended] = useState(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	if (!state.voiceData && state.voiceTranscript === undefined) {
		return <></>
	}
	const audioElement = state.voiceData ? <audio src={state.voiceData} controls={true} /> : <></>

	if (state.voiceData && state.failed?.[JSON.stringify({ type: 'VOICE_RETRIEVE_TRANSCRIPTION' })]) {
		return <div className='voice-transcript-block tip'>Transcription failed. Please try again.</div>
	}
	if (state.voiceTranscript === undefined) {
		return <div className='voice-transcript-block'>{audioElement}</div>
	}

	return <div className='voice-transcript-block tip'>
		{state.voiceTranscript || 'Voice transcript empty.'}
		{' '}
		{state.voiceTranscript && <>
			<span onClick={copyHandler(state, setCopied, setConfirmDelete)} className={copied ? 'clicked' : 'clickable'}>
				<FontAwesomeIcon icon='copy' />
				{copied ? 'Copied' : 'Copy'}
			</span>
			<div className='separator' />
			<span onClick={appendHandler(state, appended, setAppended, props.entry, props.setValue, props.formUpdated, setConfirmDelete)}
				className={appended ? 'clicked' : 'clickable'}>
				<FontAwesomeIcon icon='file-pen' />
				{appended ? 'Appended' : 'Append'}
			</span>
			<div className='separator' />
		</>}
		<span onClick={deleteHandler(confirmDelete, setConfirmDelete, dispatch)} className='clickable'>
			<FontAwesomeIcon icon='trash' />
			{confirmDelete ? 'Confirm delete' : 'Delete'}
		</span>
		{audioElement}
	</div>
}
VoiceTranscript.propTypes = ENTRY_WRITE_UPDATE_PROPS
