import React, { useContext } from 'react'
import './css/config.css'
import './css/tabs.css'
import './css/icons.css'
import './css/main.css'
import './css/navbar.css'
import './css/settings.css'
import './css/thumbnails.css'
import './css/write.css'
import './css/search.css'
import './css/responsive.css'
import NavBar from './NavBar/NavBar'
import { About } from './site/About'
import Loading from './common/Loading'
import EntryPage from './Entry/EntryPage'
import PersonPage from './Person/PersonPage'
import { Route, Switch } from 'react-router-dom'
import ErrorMessage from './common/ErrorMessage'
import ErrorBoundary from './site/ErrorBoundary'
import Login, { isLoggedIn } from './site/Login'
import { AppContext } from './contexts/AppContext'
import SettingsPage from './Settings/SettingsPage'
import PersonContextProvider from './contexts/PersonContext'
import EntryContextProvider from './contexts/EntryContext'
import SearchPage from './Search/SearchPage'
import EntrySpans from './Entry/EntrySpans'
import ExplorePage from './Explore/ExplorePage'

/** Detect the Opera browser for the sake of some CSS hacks */
const isOpera = 'opr' in window || 'opera' in window || navigator.userAgent.indexOf(' OPR/') >= 0

const LoggedInApp = (): JSX.Element => {
	const { isUserType } = useContext(AppContext)
	return <div className={ (isUserType('power') ? 'power-user' : 'standard-user') + ' ' + (isOpera ? 'opera' : '') }>
		<div className="app">
			<ErrorBoundary>
				<EntryContextProvider>
					<PersonContextProvider>
						<Switch>
							<Route path={['/about/:page', '/about']} component={About} />
							<Route path={['/settings', '/admin']} component={SettingsPage} />
							<Route path={['/entry', '/entries', '/write']} component={EntryPage} />
							<Route path={['/settings', '/admin']} component={SettingsPage} />
							<Route path={['/person', '/people']} component={PersonPage} />
							<Route path={['/explore']} component={ExplorePage} />
							<Route path={['/search']} component={SearchPage} />
							<Route path={['/spans']} component={EntrySpans} />
							<Route path="/" exact component={EntryPage} />
							<Route path='/redirect'>
								<Loading message='Redirecting you...' />
							</Route>
							<Route>
								<ErrorMessage status={404} error={{ message: 'Oh no! That path leads nowhere!' }} />
							</Route>
						</Switch>
					</PersonContextProvider>
				</EntryContextProvider>
			</ErrorBoundary>
		</div>
		<NavBar />
	</div>
}

const App = (): JSX.Element => {
	const { appState } = useContext(AppContext)
	return <>
		<Switch>
			{ !isLoggedIn(appState) && <Route path={['/about/:page', '/about']} component={About} /> }
			<Route component={ isLoggedIn(appState) ? LoggedInApp : Login } />
		</Switch>
	</>
}

export default App
