import React, { useContext } from 'react'
import PersonMetadata from './PersonMetadata'
import { PersonContext } from '../contexts/PersonContext'
import { useHistory } from 'react-router-dom'
import PropTypes, { InferProps } from 'prop-types'

const PersonThumbnail = (props: InferProps<typeof personThumbnailPropTypes>): JSX.Element => {
	const { state } = useContext(PersonContext)
	const history = useHistory()
	const onClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		// eslint-disable-next-line dot-notation
		if (event.target['classList'].contains('person-thumbnail-link')) {
			history.push('/person/' + props.personId)
		}
	}

	const person = state.people?.[props.personId]
	if (!person) {
		console.error('Displaying a PersonThumbnail without relevant state')
		return <></>
	}
	return <div className='thumbnail person-thumbnail person-thumbnail-link thumbnail-link' onClick={onClick}>
		<h3 className='person-thumbnail-title person-thumbnail-link thumbnail-link'>
			{person.name}
			{person.country && <span className='person-country'> {person.country}</span>}
		</h3>
		<PersonMetadata person={person} />
	</div>
}

const personThumbnailPropTypes = {
	personId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
PersonThumbnail.propTypes = personThumbnailPropTypes

export default PersonThumbnail
