import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type TabSet<TabId extends string> = Record<TabId, { icon: IconProp, text: string }>

const Tabs = <TabId extends string>(props: { tabs: TabSet<TabId>, changeTab: (section: TabId) => void, currentTab: TabId}): JSX.Element =>
	<div className='section-tabs'>
		{Object.keys(props.tabs).map(key => <div
			key={key}
			className={`section-tab${props.currentTab === key ? ' section-tab-selected' : ''}`}
			onClick={() => props.changeTab(key as TabId)}>
			<FontAwesomeIcon icon={props.tabs[key].icon} /> {props.tabs[key].text}
		</div>)}
	</div>

export default Tabs
