import React, { useContext } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import Graph from '../common/Graph'
import PersonMetadata from './PersonMetadata'
import PersonLink from './PersonLink'
import { PersonContext } from '../contexts/PersonContext'
import { PersonRetriever } from '../reducers/PersonReducer'

const PersonContent = (props: InferProps<typeof personContentPropTypes>): JSX.Element => {
	const { state } = useContext(PersonContext)
	const personId = typeof props.personId === 'string' ? Number.parseInt(props.personId) : props.personId
	const person = state.people?.[personId]

	// NB this component should always be wrapped by a PersonRetriever or
	//  InfiniteScroll, so it should be safe to assume all needed state is present.
	if (!person)
		return <></>

	const groupMates = renderGroupMates(person.group_mates)

	return (
		<div className='person-content'>
			<div className='thumbnail thumbnail-static person-thumbnail'>
				<h3>{person.name}
					{person.country && <span className='person-country'> {person.country}</span>}
				</h3>
				<PersonMetadata person={person} />
			</div>

			{ !!groupMates.length &&
					<div className='thumbnail thumbnail-static person-thumbnail'>
						<div className='person-group-mates'>
							Knows {groupMates}.
						</div>
					</div>}

			{ person.entries && person.entries > 1 && <PersonRetriever>
				<Graph context={PersonContext} action={{ type: 'GRAPH_PEOPLE_FOR_PERSON', person: Number(props.personId) }}/>
			</PersonRetriever> }

			{ person.entries && person.entries > 1 && <PersonRetriever>
				<Graph context={PersonContext} action={{ type: 'GRAPH_PEOPLE_OVER_TIME', people: [Number(props.personId)] }}/>
			</PersonRetriever> }
		</div>
	)
}

const personContentPropTypes = {
	personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}
PersonContent.propTypes = personContentPropTypes

const renderGroupMates = (groupMateField: string | undefined): (string | JSX.Element)[] => {
	// Process group_mates for output.
	const groupMateIds = !groupMateField
		? [] : groupMateField.split('; ')

	const groupMates = groupMateIds.map((personId: string) => [
		<PersonRetriever key={personId}>
			<PersonLink personId={personId} />
		</PersonRetriever>, ', ']).flat()
	groupMates.pop()	// Drop the last comma.
	if (groupMates.length) { groupMates[groupMates.length - 2] = ' and ' }
	return groupMates
}

export default PersonContent
