import { infiniteScrollComparisons, InfiniteScrollContextState,
	InfiniteScrollParams, InfiniteScrollParamsRaw, InfiniteScrollProps } from '../InfiniteScroll.d'
import { Id, LoadIdsAction } from '../../helpers/action/Action.d'
import { InfiniteScrollConfig } from '../../helpers/context.d'

/** Tailor context configuration into a series of props for InfiniteScroll component. */
export const infiniteScrollConfig = (config: InfiniteScrollConfig,
	state: InfiniteScrollContextState,
	params: InfiniteScrollParams | InfiniteScrollParamsRaw): InfiniteScrollProps => {
	if (!config.context)
		throw new Error('No context object provided')
	return {
		key: JSON.stringify(produceIdsAction(config, params)),
		context: config.context,
		itemCanBeRendered: state && Array.isArray(config.contextDataLocation)
			? (id: Id) => config.contextDataLocation.some(loc => id in (state[loc] || {}))
			: () => true,
		renderItem: config.renderThumbnail,
		renderOneItemOnly: config.renderDetails,
		requestDetailsAction: config.actionTypes.requestDetailsAction
			? config.actionTypes.requestDetailsAction
			: config.actionTypes.loadDetails[0],
		produceIds: produceIdsAction(config, params),
		errorMessage: config.errorMessage || `No ${config.contextDataLocation[0]} found`
	}
}

/** Used by infiniteScrollConfig */
const produceIdsAction = (config: InfiniteScrollConfig, params: InfiniteScrollParams | InfiniteScrollParamsRaw): LoadIdsAction => {
	if (!params.field)
		throw new Error('No field provided by which to search')
	return {
		type: config.actionTypes.requestIdsAction || config.actionTypes.loadIds[0],
		field: params.field,
		value: params.value,
		comparison: safeComparison(params.comparison),
		offset: Number(params.offset) || undefined
	}
}

/** Ensure the given 'comparison' string is one of the accepted options */
const safeComparison = (comparison: string | undefined): InfiniteScrollParams['comparison'] | undefined => {
	if ((infiniteScrollComparisons as readonly string[]).includes(comparison || '')) {
		return comparison as InfiniteScrollParams['comparison']
	}
	return undefined
}
