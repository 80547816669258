import React, { useContext } from 'react'
import PersonContent from './PersonContent'
import { Route, Switch } from 'react-router-dom'
import { PersonContext } from '../contexts/PersonContext'
import { PersonRetriever } from '../reducers/PersonReducer'
import { PersonThumbnailListWithField } from './PersonThumbnailList'
import ExplorePage from '../Explore/ExplorePage'

const PersonPage = (): JSX.Element => {
	const { state } = useContext(PersonContext)

	return (
		<div className="page person-page">
			<Switch>
				{ !!state.selectedPerson &&
				<Route path="/person">
					<PersonRetriever>
						<PersonContent personId={+state.selectedPerson} />
					</PersonRetriever>
				</Route>
				}
				<Route path={[
					'/people/with/:field/:comparison/:value',
					'/people/by/:field/:comparison/:value',
					'/people/with/:field/:value',
					'/people/by/:field/:value',
					'/people/by/:field'
				]}>
					<PersonThumbnailListWithField />
				</Route>
				<Route>
					<ExplorePage />
				</Route>
			</Switch>
		</div>
	)
}

export default PersonPage
